import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Button as BaseButton, Text as BaseText } from 'core/ui';
import mediaQuery from 'core/styles/mediaQuery';
import Icon from 'core/ui/Icon';
import { ModalHeading as BaseModalHeading } from 'components/Modal';
import CheckboxField from 'form-fields/styled/CheckboxField';
import StyledCheckbox from 'form-fields/styled/StyledCheckbox';

export const Button = styled(BaseButton)`
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid ${props => props.theme.borders.lightGrey};
  border-radius: 4px;
  color: ${props => props.theme.colors.primaryBlue};
  text-align: left;

  ${({ theme }) => theme.medium`
    padding-bottom: 9px !important;
  `}

  ${props => props.selected && `
    background-color: ${props.theme.colors.primaryBlue};
    color: #fff;

    &:hover {
      background-color: ${props.theme.colors.primaryBlue};
      color: #fff;
    }
  `}
`;

export const Text = styled(BaseText)`
  display: inline;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

export const Label = styled.label`
  margin-right: 10px;
`;

export const HeadlineText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.semiBoldFontFamily};
  font-size: 1.5em;
  margin-bottom: 18px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.wrap && `flex-wrap: wrap;`}
  margin: 5px 0;
`;

export const ModalContent = styled.div`
  ${({ contentHeight }) => contentHeight && `
    height: ${contentHeight}px;
  `}
  overflow: auto;
  padding: 0 10px;

  scrollbar-color: #c1c1c1 #fff;

  -webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;

export const ButtonIcon = styled(Icon)`
  font-size: 16px;
  margin-left: 15px;
  margin-top: 2px;
`;

export const DropDownRow = styled(Row)`
  border-bottom: ${props => props.noDivider ? 'none' : `1px solid ${props.theme.colors.lightGrey}`};
  margin: 15px 25px 20px;
  padding: 0 0 25px 0;

  ${mediaQuery.belowMedium`
    margin: 0;
    padding: 0;
  `}

  &:last-child {
    justify-content: space-between;
    margin-bottom: 0;
  }
`;

export const Col = styled(DropDownRow)`
  flex-direction: column;
  ${props => props.margin && `margin: ${props.margin} !important;`}
`;

export const Indent = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  ${props => props.top && `margin-top:50px;`}
`;

export const BottomRow = styled(DropDownRow)`
  border-top: 1px solid ${props => props.theme.colors.lightGrey};
  margin: 0 0 20px;
  padding: 25px;
`;

export const ModalHeading = styled(BaseModalHeading)`
  font-family: ${({ theme }) => theme.fonts.semiBoldFontFamily};
  font-size: 1.5em;
  margin-left: ${props => props.ml || '0'};
  text-align: right;
  width: 55%;
`;

export const StyledCheckboxNoLabel = styled(StyledCheckbox)`
  margin-left: 0;
`;

export const ToggleWrapper = styled.div`
  font-weight: normal;
  margin-bottom:24px;

  h3 {
    font-size: 1.125em;
    font-weight: bold;
    margin: 0 0 10px;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
`;

export const Grid = styled.div`
  display: flex;
  ${props => props.noFlex && `display: block;`}

  ${mediaQuery.belowMedium`
    display: block;
  `}
`;

export const GridColumn = styled.div`
  ${props => !props.noFlex && `flex: 1;`}
  ${props => props.towable && `max-width: inherit !important;`}
`;

export const GridButtonColumn = styled(GridColumn)`
  margin-bottom: 10px;
  margin-right: 10px;
  ${props => !props.multiRow && `max-width: 100px;`}
  ${props => props.multiRow && `flex: 1 1 120px;`}
`;

export const GridHeading = styled.h3`
  flex: 1;
`;

export const ButtonClassType = styled(BaseButton)`
  align-items: center;
  border-color: #979797;
  border-radius: 8px;
  color: #979797;
  display: flex;
  flex-direction: column;
  height: 95px;
  justify-content: space-evenly;
  padding: 0;
  width: 100%;

  svg {
    height: 50px;
    width: 80px;
  }
`;

export const HiddenCheckbox = styled(CheckboxField)`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  opacity: 0.00001;

  &:checked ~ ${ButtonClassType} {
    background-color: ${props => props.theme.colors.primaryBlue};
    color: #fff;
  }

  &:checked ~ ${ButtonClassType} svg > path {
    stroke: #fff;
  }

  &:checked ~ ${ButtonClassType} svg > g {
    fill: #fff;
  }

  &:not(:checked) ~ ${ButtonClassType} svg > path {
    stroke: #979797;
  }

  &:not(:checked) ~ ${ButtonClassType} svg > g {
    fill: #979797;
  }
`;

export const ButtonLabel = styled.label`
  cursor: pointer;
  position: relative;

  &:hover input:not(:checked) ~ ${ButtonClassType} {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.darkBlue};
    border-color: ${props => props.theme.colors.lightGrey};
  }

  &:hover input:not(:checked) ~ ${ButtonClassType} svg > path {
    stroke: ${props => props.theme.colors.darkBlue};
  }

  &:hover input:not(:checked) ~ ${ButtonClassType} svg > g {
    fill: ${props => props.theme.colors.darkBlue};
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const KeywordWrapper = styled.div`
  border: 1px solid #979797;
  border-radius: 8px;
  padding: 10px 12px 15px 5px;
  position: relative;

  svg {
    color: inherit;
    margin-right: 10px;
    margin-top: 8px !important;
    position: relative !important;
  }

  div.react-autosuggest__container {
    left:45px;
    position: absolute;
    top: 8px;
    width: 86%;
  }

  input {
    border: none !important;
    width: 100% !important;
  }
`;

export const MobileCol = styled.div`
  border-bottom: ${props => props.noDivider ? 'none' : `1px solid ${props.theme.colors.lightGrey}`};
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
  padding: 0;
`;

export const RentalOptionText = styled(Col)`
  margin-right: 10px;
  padding: 0;

  ${mediaQuery.belowMedium`
    padding-right: 28px;
    width: 275px;
  `}
`;

export const RentalOptionCol = styled(Col)`
  border: none !important;
  margin: 5px 0 0 !important;
  padding: 0 !important;
`;
