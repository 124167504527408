import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  border: 1px solid ${props => props.theme.colors.grey200};
  box-shadow: 0 4px 8px rgba(63, 63, 59, 0.15);
  border-radius: 8px;
  width: 100%;
`;

const CardLink = styled.a`
  color: ${props => props.theme.colors.grey800};
  font-size: 1rem;
  text-decoration: none;
`;

const Meta = styled.div`
  padding: 16px;
`;

const Date = styled.h3`
  color: ${props => props.theme.colors.groveGreen};
  font-size: 1.25rem;
  line-height: 26px;
  font-family: 'Barlow', 'Source Sans Pro', sans-serif;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
`;

const Title = styled.p`
  font-family: 'Barlow Semi Condensed', 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 22px;
  color: ${props => props.theme.colors.grey800};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0.5rem 0;
`;

const GenericText = styled.p`
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 0 0.5rem 0;
`;

const Image = styled.img`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 160px;
  width: 100%;
  object-fit: cover;
`;

export default function EventCard({card}) {
  return (
    <Card>
      <CardLink href={card.url}>
        <Image src={card.img} alt={card.title} loading='lazy' width='275' height='160' />
        <Meta>
          <Date>{card.start_date} { card.end_date && <span>- {card.end_date}</span>}</Date>
          <Title>{card.title}</Title>
          <GenericText>{card.location}</GenericText>
          <GenericText>Price: {card.pricing}</GenericText>
        </Meta>
      </CardLink>
    </Card>
  )
}