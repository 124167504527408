import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useToggle from 'core/hooks/useToggle';
import { Link as BaseLink, Button as BaseButton } from 'core/ui';

const textStyles = css`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 15px;
  font-weight: normal;
`;

const H5 = styled.h5`
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-count: 3;

  ${({ theme }) => theme.medium`
    column-count: 4;
  `}
`;

const Li = styled.li`
  ${textStyles}
  margin-bottom: 10px;
`;

const Link = styled(BaseLink)`
  ${textStyles}
`;

const Button = styled(BaseButton)`
  font-weight: normal;
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;

  &:focus {
    outline: none;
  }
`;

function RegionList({ regionList }) {
  const featuredRegions = useMemo(() => regionList.filter(region => region.featured), [regionList]);
  const [regions, setRegions] = useState(featuredRegions);
  const [onlyFeaturedVisible, handleToggle] = useToggle(true);

  const toggleAllCities = () => {
    const featuredVisible = !onlyFeaturedVisible;
    handleToggle();
    setRegions(featuredVisible ? featuredRegions : regionList);
  };

  return (
    <>
      <H5>Popular Destinations</H5>
      <Ul data-id="region-list">
        {regions.map(region => (
          <Li key={region.name}>
            <Link
              href={region.url}
              data-event-category="Search"
              data-event-action={region.name}
              data-event-label="Popular Destinations"
            >
              {region.name}
            </Link>
          </Li>
        ))}
      </Ul>
      {regionList.length !== featuredRegions.length && (
        <Button
          type="button"
          variant="linkPrimary"
          onClick={toggleAllCities}
          data-id="see-all-cities"
          data-event-category="Search"
          data-event-action="Show {onlyFeaturedVisible ? 'All' : 'Popular'} Cities"
          data-event-label="Popular Destinations"
        >
          Show {onlyFeaturedVisible ? 'All' : 'Popular'} Cities
        </Button>
      )}
    </>
  );
}

RegionList.defaultProps = {
  regionList: [],
};

RegionList.propTypes = {
  regionList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      featured: PropTypes.bool,
    }),
  ),
};

export default React.memo(RegionList);
