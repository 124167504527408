import React from 'react';
import styled from 'styled-components';

import { withCurrentUser } from 'core/state/Globals';
import ResponsiveRender from 'components/ResponsiveRender';

import useSearchFilters from '../../hooks/useSearchFilters';
import SearchFiltersMobileButtons from './SearchFiltersMobileButtons';
import DesktopSearchFilters from './DesktopSearchFilters';

const FiltersWrapper = styled.div`
  position: sticky;
  top: ${({ stickyHeight }) => `${stickyHeight}px` || '5.38rem'};
  z-index: 3;
  background: #fff;

  ${({ theme }) => theme.medium`
    top: ${({ stickyHeight }) => `${stickyHeight}px` || '4.3rem'};
    outline: red;
  `}

  ${({ theme }) => theme.large`
    top: ${({ stickyHeight }) => `${stickyHeight}px` || '5.38rem'};
    outline: green;
  `}
`;

function SearchFilters(props) {
  const {
    currentUser,
    onSearchMapToggle,
    searchMapVisible,
    hasResults,
    nightlyRateStats,
    histograms,
    stickyHeight,
  } = props;
  const { filters, changeSearchFilters, isFilterSelected } = useSearchFilters();
  const isLoggedIn = !!currentUser;

  const passthroughProps = {
    currentUser,
    onSearchMapToggle,
    searchMapVisible,
    filters,
    changeSearchFilters,
    isFilterSelected,
    hasResults,
    nightlyRateStats,
    histograms,
  };

  return (
    <ResponsiveRender>
      {isMobile =>
        isMobile ? (
          <SearchFiltersMobileButtons {...passthroughProps} />
        ) : (
          <FiltersWrapper
            id="filters-container"
            isLoggedIn={isLoggedIn}
            stickyHeight={stickyHeight}
          >
            <DesktopSearchFilters {...passthroughProps} />
          </FiltersWrapper>
        )
      }
    </ResponsiveRender>
  );
}

function propsAreEqual(prevProps, nextProps) {
  return (
    prevProps.searchMapVisible === nextProps.searchMapVisible &&
    prevProps.hasResults === nextProps.hasResults &&
    prevProps.stickyHeight === nextProps.stickyHeight
  );
}

export default React.memo(withCurrentUser(SearchFilters), propsAreEqual);
