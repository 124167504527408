import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import styled from 'styled-components';

import { SwiperNavButtons } from 'content/events/SwiperNavButton';
import EventCard  from 'content/events/EventCard';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/modules/pagination/pagination.scss';

const StyledSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 200px;

  @media (max-width: 992px) {
    padding-top: 30px;
  }

  .swipper-wrapper {
    padding: 0 6px;
  }
  .swiper-slide {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    margin: 40px 0;

    span {
      background-color: ${props => props.theme.colors.coreBlue};
    }
  }
`;

const Heading = styled.h3`
  margin-bottom: 0;
`;

export default ({heading, cards}) => {
  return (
    cards &&
      <>
        <Heading>{heading}</Heading>
        <StyledSwiper
          modules={[Navigation, Pagination]}
          pagination={{
            dynamicBullets: true,
            dynamicMainBullets: 3
          }}
          spaceBetween={25}
          breakpoints={{
            '@0.3': {
              slidesPerView: 1
            },
            '@0.5': {
              slidesPerView: 2
            },
            '@0.75': {
              slidesPerView: 4
            }
          }}
        >
          <SwiperNavButtons />
          {cards.map((card, index) => (
            <SwiperSlide key={`card-${index + 1}`}>
              <EventCard card={card} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </>
  );
};