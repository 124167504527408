import React, { useRef } from 'react';
import styled from 'styled-components';

import { Button, Flex } from 'core/ui';
import useSticky from 'core/hooks/useSticky';
import ToggleContent from 'components/ToggleContent';

import SearchFiltersModal from './SearchFiltersModal';
import MobileMapToggle from './MobileMapToggle';

const SearchFiltersMobile = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: #fff;
  padding-bottom: 15px;
  z-index: 3;
`;

function NewSearchFiltersMobileButtons({
  onSearchMapToggle,
  searchMapVisible,
  hasResults,
  filters,
  changeSearchFilters,
  isFilterSelected,
}) {
  const wrapperRef = useRef(null);
  useSticky(wrapperRef);

  const moreFiltersSelected = isFilterSelected([
    'amenities',
    'cancellationPolicy',
    'electricService',
    'electricService.30',
    'electricService.50',
    'delivery',
    'fromYear',
    'instantBook',
    'isStationary',
    'keywords',
    'min',
    'max',
    'maxGenHours',
    'minGenHours',
    'maxLength',
    'minLength',
    'maxMiles',
    'minMiles',
    'minWater',
    'maxWater',
    'pets',
    'rvClass',
    'smoking',
    'toYear',
  ]);

  return (
    <SearchFiltersMobile
      id="search-filters-mobile"
      pl="15px"
      pr="5px"
      pt="10px"
      justifyContent="space-between"
      ref={wrapperRef}
    >
      <div>
        <ToggleContent
          toggle={show => (
            <Button
              type="button"
              onClick={show}
              variant={moreFiltersSelected ? 'blue' : 'blueBorder'}
              fontSize="15px"
              borderRadius="20px"
            >
              Filters
            </Button>
          )}
          content={hide => (
            <SearchFiltersModal
              isOpen
              hideModal={hide}
              onSubmit={changeSearchFilters}
              filters={filters}
              isFilterSelected={isFilterSelected}
            />
          )}
        />
      </div>

      {hasResults && (
        <MobileMapToggle
          onSearchMapToggle={onSearchMapToggle}
          searchMapVisible={searchMapVisible}
        />
      )}
    </SearchFiltersMobile>
  );
}

export default React.memo(NewSearchFiltersMobileButtons);
