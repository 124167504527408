/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toIntOrNull } from 'core/helpers/Formatters';
import { Flex, Text } from 'core/ui';
import BarGraph from 'components/BarGraph';
import Slider from 'components/Slider';
import FieldsetInput from 'components/FieldsetInput';
import { useDevice } from 'core/hooks/useDevice';
import StyledCheckbox from 'form-fields/styled/StyledCheckbox';

const Col = styled.div`
  flex: 1;
  flex-direction: column;
`;

const DashCol = styled(Col)`
  line-height: 48px;
  max-width: 30px;
  text-align: center;
  vertical-align: middle;
`;

const UnlimitedWrapper = styled(Flex)`
  margin-top: 15px;
`;

const SliderWrapperStyled = styled.div`
  height: 150px;
  ${props => props.disabled && `
    display: none;
  `}
  ${props => !props.withBarGraph && `
    height: inherit;
    margin-top: 20px;
  `}
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;

  ${({ theme }) => theme.medium`
    padding-left: 0;
    padding-right: 0;
  `}
`;

const InputContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;

`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 13px;
  font-weight: normal;
  margin-top: 1.5rem;
`;

const sanitizeValuesToSave = (value, defaultValue) => {
  // if the value is equal to the default value, set it to an empty string
  // so then we don't save the min/max in the url (or send to the API)
  return value === defaultValue ? '' : value;
};
function SliderWithInputsBargraph(props) {
  const {
    fieldKeyMin,
    fieldKeyMax,
    defaultMin,
    defaultMax,
    step,
    title,
    handleSaveValues,
    className,
    children,
    min, // current value from filters state/url (NOT Formik)
    max, // current value from filters state/url (NOT Formik)
    sliderLabel,
    barData,
    showUnlimited,
    selectUnlimited,
    unlimitedName,
    unlimitedValue,
    srTextMin,
    srTextMax,
  } = props;

  const [unlimitedIsChecked, setUnlimitedIsChecked] = useState(selectUnlimited);

  const sliderIdMin = `input-${sliderLabel.toLowerCase()}-min`;
  const sliderIdMax = `input-${sliderLabel.toLowerCase()}-max`;

  const { isMobile } = useDevice();
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const initialMin = selectUnlimited ? defaultMin : (toIntOrNull(min) || defaultMin);
  const initialMax = selectUnlimited ? defaultMax : (toIntOrNull(max) || defaultMax);

  const [inputValues, setInputValues] = useState([
    initialMin,
    initialMax === defaultMax ? `${initialMax}+` : initialMax,
  ]);
  const [sliderValues, setSliderValues] = useState([initialMin, initialMax]);
  const [mutedBarData, setMutedBarData] = useState(barData);

  useEffect(() => {
    if (barData.length) {
      // determine which bars are not muted
      const tmpData = barData.map( (bar) => {
        const barRightSide = bar.end;
        const barLeftSide = bar.start;
        const a = (isNaN(sliderValues[0]) ? defaultMin : sliderValues[0]);
        const b = (isNaN(sliderValues[1]) ? defaultMax : sliderValues[1]);
        if ( barLeftSide >= a && barRightSide <= b) {
          return {...bar}
        }

        return {...bar, muted: true }
      });

      setMutedBarData(tmpData)
    }
  }, [sliderValues, barData, defaultMax, defaultMin]);

  const onSave = useCallback(
    values => {
      handleSaveValues([
        sanitizeValuesToSave(values[0], defaultMin),
        sanitizeValuesToSave(values[1], defaultMax),
      ]);
    },
    [handleSaveValues, defaultMin, defaultMax]
  );

  /**
   * Add a `plus` symbol if the `max` is equal to the `max` we allow
   * @param {[min, max]} values
   */
  const transformValuesForDisplay = values => {
    if (unlimitedValue === values[1]) {
      return [values[0], `${defaultMax}+`];
    }
    return values[1] === defaultMax ? [values[0], `${values[1]}+`] : values;
  }

  /**
   * Normalize the values so the slider min does not cross the max
   * and so the user isn't setting values we can't handle
   * @param {[min, max]} valuesArray
   * @param {fieldKeyMin | fieldKeyMax} keyChanged
   * @returns {[min,max]} - returns the manipulated min and max values
   */
  const normalizeValues = (valuesArray, keyChanged) => {
    const [minVal, maxVal] = valuesArray;
    if (minVal > maxVal) {
      if (keyChanged === fieldKeyMin) {
        return [minVal, minVal + 10];
      }

      if (keyChanged === fieldKeyMax) {
        const newMin = maxVal - 50;
        return [newMin < 0 ? 0 : newMin, maxVal];
      }
    }

    return valuesArray;
  };

  const sanitizeInputValues = (values) => {
    let minTmp = toIntOrNull(values[0].toString().replace('+', ''));
    minTmp = isNaN(minTmp) ? defaultMin : minTmp;

    let maxTmp = toIntOrNull(values[1].toString().replace('+', ''));
    maxTmp = isNaN(maxTmp) ? defaultMax : maxTmp;

    return [minTmp, maxTmp];
  }

  const handleInputChange = event => {
    const {
      target: { name, value },
    } = event;
    const values = [
      (isNaN(inputValues[0]) ? defaultMin : inputValues[0]).toString(),
      (isNaN(inputValues[1]) ? defaultMax : inputValues[1]).toString(),
    ];
    const index = name === fieldKeyMin ? 0 : 1; // get the index that changed
    values.splice(index, 1, value); // modifies `vals` in place

    const updatedInputValues = values.map(val => {
      if (val.includes(`${defaultMax}+`)) {
        // only allow the defaultMax value to have a plus after it
        return val;
      }

      return val.replace('+', '');
    });

    setInputValues(updatedInputValues);
  };

  // sets errors if needed
  // returns true if no errors
  const validateValues = useCallback((minValue, maxValue) => {
    if (minValue > maxValue) {
      // if min is not less than or equal to max we should not "save" the values
      // and instead let the user know they need to fix it
      setError(`Minimum ${sliderLabel.toLowerCase()} must be less than or equal to the maximum ${sliderLabel.toLowerCase()}`);
      return false;
    }

    if (error) {
      // if the above `minIsLTEMax` checks is truthy and we get here but still have an error
      // we should clear the error
      setError(null);
    }

    return true;
  }, [error, sliderLabel]);

  const handleInputBlur = event => {
    if (disabled) return;

    const {
      target: { name },
    } = event;

    const sanitizedValues = (sanitizeInputValues(inputValues))

    if (!validateValues(...sanitizedValues)) {
      return;
    }

    const updatedValues = normalizeValues(sanitizedValues, name);

    setInputValues(transformValuesForDisplay(updatedValues));
    setSliderValues(updatedValues);
    onSave(updatedValues);
  };

  const clearUnlimited = () => {
    setDisabled(false);
    setUnlimitedIsChecked(false);
  };

  const handleClear = () => {
    const defaults = [defaultMin, defaultMax];
    clearUnlimited();
    setSliderValues(defaults);
    setInputValues(defaults);
    onSave(['','']);
  };

  const handleCheck = () => {
    handleClear();
    if (unlimitedIsChecked) {
      clearUnlimited();
      onSave(['', '']);
    } else {
      setDisabled(true);
      setUnlimitedIsChecked(true);
      onSave(['', unlimitedValue]);
    }
  };

  useEffect(() => {
    if (min === '' && max === '') {
      handleClear();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);

  const handleSliderChange = useCallback(
    vals => {
      setSliderValues(vals);
      onSave(vals);
    },
    [onSave]
  );

  const handleSliderUpdate = useCallback(vals => {
    validateValues(...vals);
    setInputValues(vals);
  }, [validateValues]);

  return (
    <div className={className}>
      {title && <Text mb="10px">{title}</Text>}

      <SliderWrapperStyled
        disabled={disabled || unlimitedIsChecked}
        withBarGraph={!!barData.length}
      >
        {!!barData.length &&
          <BarGraph data={mutedBarData} />
        }
        <Slider
          values={sliderValues}
          onUpdate={handleSliderUpdate}
          onChange={handleSliderChange}
          allowedMin={defaultMin}
          allowedMax={defaultMax}
          step={step}
          mode={2}
          bargraph
          disabled={disabled || unlimitedIsChecked}
          srTextMin={srTextMin}
          srTextMax={srTextMax}
        />
      </SliderWrapperStyled>

      <InputContainer>
        <Col>
          <FieldsetInput
            legend={`Min ${sliderLabel}`}
            name={fieldKeyMin}
            value={inputValues[0]}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoComplete="off"
            id={sliderIdMin}
            pattern={isMobile ? "[0-9]*" : undefined}
            disabled={disabled || unlimitedIsChecked}
          />
        </Col>
        <DashCol>-</DashCol>
        <Col>
          <FieldsetInput
            legend={`Max ${sliderLabel}`}
            name={fieldKeyMax}
            value={inputValues[1]}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoComplete="off"
            id={sliderIdMax}
            pattern={isMobile ? "[0-9]*" : undefined}
            disabled={disabled || unlimitedIsChecked}
          />
          {showUnlimited &&
            <UnlimitedWrapper>
              <StyledCheckbox name={unlimitedName} onChange={handleCheck} checked={unlimitedIsChecked}>
                Unlimited {sliderLabel}{!isMobile && ` only`}
              </StyledCheckbox>
            </UnlimitedWrapper>
          }
        </Col>
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {children && children(handleClear)}
    </div>
  );
}

SliderWithInputsBargraph.defaultProps = {
  step: 10,
  title: null,
  className: null,
  children: null,
  min: '',
  max: '',
  sliderLabel: '',
  barData: [],
  showUnlimited: false,
  selectUnlimited: false,
  srTextMax: 'adjust maximum slider value',
  srTextMin: 'adjust minimum slider value',
  unlimitedName: 'unlimited-checkbox',
  unlimitedValue: -1,
};

SliderWithInputsBargraph.propTypes = {
  fieldKeyMin: PropTypes.string.isRequired,
  fieldKeyMax: PropTypes.string.isRequired,
  defaultMin: PropTypes.number.isRequired,
  defaultMax: PropTypes.number.isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSaveValues: PropTypes.func.isRequired,
  srTextMax: PropTypes.string,
  srTextMin: PropTypes.string,
  step: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  sliderLabel: PropTypes.string,
  barData: PropTypes.array,
  showUnlimited: PropTypes.bool,
  selectUnlimited: PropTypes.bool,
  unlimitedName: PropTypes.string,
  unlimitedValue: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]),
};

export default memo(SliderWithInputsBargraph);
