import React from 'react';
import get from 'just-safe-get';

import Dropdown from 'components/DropdownCustom';
import PriceFilterSlider from './PriceFilter';

function PriceFilter(props) {
  const {
    isFilterSelected,
    filters: { min, max },
    handleFormikSaveValues,
    nightlyRateHistogram,
  } = props;

  const { buckets } = nightlyRateHistogram;
  const selected = isFilterSelected(['min', 'max']);

  const text = selected && buckets ? `Up to $${max || buckets[buckets.length-1].key}` : 'Nightly Price';
  return (
    <Dropdown
      button={text}
      buttonActive={selected}
      id="price-slider"
      dropdownStyles={{ minWidth: '400px', maxWidth: '475px' }}
    >
      {({ handleToggle }) => (
        <PriceFilterSlider
          filters={{min, max}}
          closeDropdown={handleToggle}
          handleSaveValues={handleFormikSaveValues}
          buckets={buckets}
        />
      )}
    </Dropdown>
  );
}

function propIsEqual(prevProps, nextProps, path) {
  return get(prevProps, path) === get(nextProps, path);
}

function arePropsEqual(prevProps, nextProps) {
  return (
    propIsEqual(prevProps, nextProps, 'filters.min') &&
    propIsEqual(prevProps, nextProps, 'filters.max')
  );
}

export default React.memo(PriceFilter, arePropsEqual);
