import stickybits from 'stickybits';

export default (elementSelector, opts) => {
  const bits = stickybits(elementSelector, opts);

  if (bits && bits.props.positionVal === 'fixed') {
    // if position: fixed is being used (in IE11 for example)
    // just remove the sticky plugin and fall back to existing styles
    bits.cleanup();
  }

  return bits;
};
