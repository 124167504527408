/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { connect as connectFormik } from 'formik';

import useSearchFilters from 'search/hooks/useSearchFilters';
import useWindowSize from 'core/hooks/useWindowSize';

import Modal, { ModalHeader, CloseButtonStyled } from 'components/Modal';
import ToggleContent from 'components/ToggleContent';
import SafeTracking from 'tracking/SafeTracking';
import LengthFilter from '../LengthFilter';
import MileageFilter from '../MileageFilter';
import GeneratorFilter from '../GeneratorFilter';
import ElectricServiceFilter from '../ElectricServiceFilter';
import MiscFilter from '../MiscFilter';
import FreshWaterTankFilter from '../FreshWaterTankFilter';
import ApplyButtons from '../ApplyButtons';

import {
  BottomRow,
  Button,
  ButtonIcon,
  Col,
  DropDownRow,
  HeadlineText,
  Indent,
  ModalContent,
  ModalHeading,
  Text
} from '../filterStyles';

function TripDropdown(props) {
  const {
    filters,
    isFilterSelected,
    title,
    handleFormikSaveValues,
    formik,
    totalResults,
  } = props;
  const [selectedCountText, setSelectedCountText] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  const { height } = useWindowSize();
  const contentHeight = height * .7;
  const applyButtonText = totalResults ? `Show ${totalResults} RVs` : 'Apply changes';

  const handleSaveValues = (updatedValues) => {
    Object.keys(updatedValues).forEach((key) => {
      formik.setFieldValue(key, updatedValues[key], false);
    });
  };

  const { clearAllFilters } = useSearchFilters();

  const handleClear = () => {
    clearAllFilters(['sleeps']);
  };

  const filtersSelectedList = [
    'amenities',
    'electricService',
    'electricService.30',
    'electricService.50',
    'maxGenHours',
    'minGenHours',
    'maxLength',
    'minLength',
    'maxMiles',
    'minMiles',
    'minWater',
    'maxWater',
  ];

  useEffect(() => {
    const selectedCount = isFilterSelected(filtersSelectedList, true);
    // use temp var for State timing issues.
    const tmpIsSelected = !!(selectedCount && selectedCount > 0);
    setIsSelected(tmpIsSelected);
    setSelectedCountText(tmpIsSelected ? ` (${selectedCount})` : '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOnClick = (show) => {
    SafeTracking.track('Trip Planning CTA Click',
      { rvsIntegrations: { all: false, segment: true } });
    show();
  };

  return (
    <ToggleContent
      toggle={show => (
        <Button
          type="button"
          onClick={() => handleOnClick(show)}
          selected={isSelected}
          data-event-category="Search Filter Nav"
          data-event-action={`${title} Icon`}
          data-event-label={title}
        >
          <Text ml="8px" mr="10px" fontSize="14px">
            {title}{selectedCountText}
            <ButtonIcon className="ArrowIcon" icon='angle-down' />
          </Text>
        </Button>
      )}
      content={hide => (
        <Modal
          isOpen
          onRequestClose={hide}
          data={{ id: 'tip-planning-modal' }}
          style={{ overlay: { overflowY: 'scroll' }, content: { borderRadius: '16px', maxWidth: '780px' } }}
        >
          <ModalHeader alignItems="flex-start" justifyContent="space-between">
            <ModalHeading ml={'32px'}>{title}</ModalHeading>

            <CloseButtonStyled type="button" onClick={hide} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </CloseButtonStyled>
          </ModalHeader>
          <ModalContent contentHeight={contentHeight}>
            <Col>
              <HeadlineText>Power and Water</HeadlineText>
              <Indent>
                <ElectricServiceFilter
                  filters={filters}
                  formik={formik}
                  handleFormikSaveValues={handleFormikSaveValues}
                />
                <MiscFilter
                  amenity="Electric Generator"
                  modifier="an"
                  filters={filters}
                  handleFormikSaveValues={handleFormikSaveValues}
                />
                <MiscFilter
                  amenity={`Hot & Cold Water Supply`}
                  filters={filters}
                  handleFormikSaveValues={handleFormikSaveValues}
                />
              </Indent>
            </Col>
            <DropDownRow>
              <MileageFilter
                handleSaveValues={handleSaveValues}
                formik={formik}
                filters={filters}
              />
            </DropDownRow>
            <DropDownRow>
              <GeneratorFilter
                filters={filters}
                formik={formik}
                handleSaveValues={handleSaveValues}
              />
            </DropDownRow>
            <DropDownRow>
              <LengthFilter
                filters={filters}
                formik={formik}
                handleSaveValues={handleSaveValues}
              />
            </DropDownRow>
            <DropDownRow noDivider>
              <FreshWaterTankFilter
                filters={filters}
                handleSaveValues={handleSaveValues}
              />
            </DropDownRow>
          </ModalContent>
          <BottomRow>
            <ApplyButtons
              applyText={applyButtonText}
              applyVariant='blue'
              clearText='Clear all'
              closeDropdown={hide}
              handleClear={handleClear}
            />
          </BottomRow>
        </Modal>
      )}
    />
  );
}

export default connectFormik(React.memo(TripDropdown));
