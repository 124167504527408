import React from 'react';
import get from 'just-safe-get';
import styled from 'styled-components';

import { withSearchHistograms } from 'core/state/Globals';
import SliderWithInputsBargraph from 'components/SliderWithInputsBargraph';

import { HeadlineText, Indent } from './filterStyles';

const MIN_KEY = 'minLength';
const MAX_KEY = 'maxLength';
const INTERVAL = 1;

const Wrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

function LengthFilter({
  filters,
  formik,
  handleSaveValues,
  includeHeader = true,
  indent = true,
  searchHistograms
}) {
  const { minLength, maxLength } = filters;
  const { buckets } = searchHistograms.lengthHistogram;
  const lengthMin = buckets[0].key;
  const lengthMax = buckets[buckets.length -1].key + INTERVAL;

  const min = get(formik, ['values', MIN_KEY]) || minLength;
  const max = get(formik, ['values', MAX_KEY]) || maxLength;

  const data = buckets.map((bucket => {
    return {
      distance: bucket.doc_count,
      colors: ["#91918e", "#91918e"],
      label: bucket.key,
      start: bucket.key,
      end: bucket.key + INTERVAL
    }
  }));

  const handleSave = vals => {
    handleSaveValues({
      [MIN_KEY]: vals[0],
      [MAX_KEY]: vals[1],
    });
  }

  const sliderProps = {
    fieldKeyMin: MIN_KEY,
    fieldKeyMax: MAX_KEY,
    defaultMin: lengthMin,
    defaultMax: lengthMax,
    step: 1,
    handleSaveValues: handleSave,
    min,
    max,
    isMoney: false,
    barData: data,
    offset: lengthMin,
    srTextMax: "adjust maximum RV length",
    srTextMin: "adjust minimum RV length",
  };

  return (
    <Wrapper>
      {includeHeader &&
        <HeadlineText className="filterHeadline">Length (in feet)</HeadlineText>
      }
      {indent ?
        <Indent top>
          <SliderWithInputsBargraph {...sliderProps} />
        </Indent>
      :
        <SliderWithInputsBargraph {...sliderProps} />
      }
    </Wrapper>

  );
}

export default React.memo(withSearchHistograms(LengthFilter));
