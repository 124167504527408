import React, { useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import { Popover } from 'components/Tippy';
import { Button as BaseButton } from 'core/ui';
import Icon from 'core/ui/Icon';

const Button = styled(BaseButton)`
  display: inline-block;
`;

const ArrowIcon = styled(({ dropdownOpen, ...rest }) => <Icon {...rest} />)`
  vertical-align: middle;
`;

const PopoverStyled = styled(Popover)`
  background-color: #fff;
  border: 1px solid ${props => props.theme.borders.lightGrey};
  border-radius: 4px;
  color: ${props => props.theme.colors.darkBlue};
  padding: 10px 30px;
  text-align: left;
  ${props => props.dropdownStyles || ''}
`;

function Dropdown(props) {
  const { component: Component, handleToggled, componentProps } = props;
  const tippy = useRef(null);

  const onCreate = (instance) => {
    tippy.current = instance;
  };

  const onHide = () => {
    handleToggled(false);
  };

  const onShow = ({ popper }) => {
    handleToggled(true, popper);
  };

  const closeTippy = () => {
    tippy.current.hide();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // ENTER key
      closeTippy();
    }

    if (event.keyCode === 27) {
      closeTippy();
    }
  };

  const content = (
    <Component
      closeDropdown={closeTippy}
      handleKeyDown={handleKeyDown}
      {...componentProps}
    />
  );

  return (
    <>
      <PopoverStyled
        onCreate={onCreate}
        content={content}
        onShow={onShow}
        onHide={onHide}
        placement="bottom-start"
        maxWidth={props.maxWidth || '350px'}
        dropdownStyles={props.dropdownStyles}
        suppressClassNameWarning
      >
        {props.children}
      </PopoverStyled>
    </>
  );
}

export default function DropdownWithTrigger(props) {
  const { onPopoverShow, children, selected, uniqueId, buttonVariant = 'greyBorder' } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleToggled = useCallback(
    (open, popper) => {
      if (open && onPopoverShow) {
        onPopoverShow(popper);
      }

      setDropdownOpen(open);
    },
    [onPopoverShow],
  );

  return (
    <Dropdown {...props} handleToggled={handleToggled}>
      <Button
        type="button"
        variant={buttonVariant}
        aria-haspopup="true"
        aria-expanded={!!dropdownOpen}
        dropdownOpen={dropdownOpen}
        className={selected || dropdownOpen ? 'active' : ''}
        data-id={`dropdownComponent__${uniqueId || ''}`}
      >
        {children}
        <ArrowIcon icon={dropdownOpen ? 'angle-up' : 'angle-down'} dropdownOpen={dropdownOpen} />
      </Button>
    </Dropdown>
  );
}
