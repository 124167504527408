import React from 'react';
import { Router, StaticRouter } from 'react-router-dom';
import canUseDOM from 'can-use-dom';

export default function IsomorphicRouter({ history, children }) {
  if (canUseDOM) {
    return (
      <Router history={history}>
        {children}
      </Router>
    );
  }

  return (
    <StaticRouter location={history.location} context={{}}>
      {children}
    </StaticRouter>
  );
}
