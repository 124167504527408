import React from 'react';
import styled from 'styled-components';

import WhyRentWithRVShare from 'search/components/WhyRentWithRVShare';
import RandomRegionalCopy from 'search/helpers/RandomRegionalCopy';

const Headline = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const RegionalCopy = ({ body, destination, total, location, discoverTitle }) => (
  <div>
    <Headline>Why Rent with RVshare?</Headline>
    <WhyRentWithRVShare />
    {body ? (
      <div dangerouslySetInnerHTML={{ __html: body }} />
    ) : (
      <RandomRegionalCopy {...{ destination, total, location, discoverTitle }} />
    )}
  </div>
);
export default RegionalCopy;
