/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RAIL_HEIGHT = '5px';
const RAIL_BORDER_RADIUS = '4px';
// *******************************************************
// RAIL
// *******************************************************

const RailOuterStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 42px;
  transform: translate(0%, -50%);
  border-radius: ${RAIL_BORDER_RADIUS};
  cursor: pointer;
  touch-action: manipulation;
`;

const RailInnerStyled = styled.div`
  position: absolute;
  width: 100%;
  height: ${RAIL_HEIGHT};
  transform: translate(0%, -50%);
  border-radius: ${RAIL_BORDER_RADIUS};
  pointer-events: none;
  background-color: ${props => props.bargraph ? 'rgb(220, 220, 220)' : 'rgb(155, 155, 155)'};
  touch-action: manipulation;
`;

export function SliderRail({ getRailProps, bargraph }) {
  return (
    <>
      <RailOuterStyled {...getRailProps()} />
      <RailInnerStyled bargraph={bargraph} />
    </>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
  bargraph: PropTypes.bool,
};

SliderRail.defaultProps = {
  bargraph: false,
};


// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************

const KeyboardHandleStyled = styled.button`
  background-color: ${props => (props.disabled ? '#666' : '#fff')};
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.42);
  cursor: pointer;
  padding: 0;
  position: absolute;
  left: ${props => `${props.percent}%`};
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  z-index: 2;
  touch-action: manipulation;

  &:focus {
    outline: none;
  }

  &:active {
    border: none;
  }
`;

function BargraphHandle({ srText }) {
  return (
    <>
      <Bar />
      <Bar />
      <Bar />
      <span className="sr-only">{srText}</span>
    </>
  )
};

const Bar = styled.div`
  background-color: rgb(175, 175, 175) !important;
  display: inline-block !important;
  height: 9px !important;
  line-height: 1.5;
  margin-left: 1px !important;
  margin-right: 1px !important;
  width: 1px !important;
`;
export function KeyboardHandle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
  bargraph,
  srText,
}) {
  return (
    <KeyboardHandleStyled
      type="button"
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      disabled={disabled}
      percent={percent}
      {...getHandleProps(id)}
    >
      {bargraph && <BargraphHandle srText={srText} />}
    </KeyboardHandleStyled>
  );
}

KeyboardHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  srText: PropTypes.string,
};

KeyboardHandle.defaultProps = {
  disabled: false,
  srText: 'adjust slider minimum',
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************

const TrackStyled = styled.div`
  // keep together, in this order...
  background-color: ${props => props.theme.colors.yellow};
  ${props => props.bargraph && `background-color: rgb(128, 128, 128);`};
  ${props => props.disabled && `background-color: #ccc;`};
  // end keep together

  position: absolute;
  transform: translate(0%, -50%);
  height: ${RAIL_HEIGHT};
  z-index: 1;
  border-radius: ${RAIL_BORDER_RADIUS};
  cursor: pointer;
  left: ${props => `${props.left}%`};
  width: ${props => `${props.width}%`};
  touch-action: manipulation;
`;

export function Track({ source, target, getTrackProps, disabled, bargraph }) {
  return (
    <TrackStyled
      width={target.percent - source.percent}
      left={source.percent}
      disabled={disabled}
      bargraph={bargraph}
      {...getTrackProps()}
    />
  );
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Track.defaultProps = {
  disabled: false,
};
