import React from 'react';
import styled from 'styled-components';

import { Flex, Box } from 'core/ui';

import RVIcon from 'images/valueprop-rv.svg';
import GlobeIcon from 'images/valueprop-globe.svg';
import LockIcon from 'images/valueprop-lock.svg';

const Container = styled(Flex)`
  background: #838c91; // one-off color
  color: white;
  justify-content: space-between;
  padding: 2rem;

  // counteract bootstrap in erb
  margin: 2rem -15px 4rem -15px;

  ${({ theme }) => theme.medium`
    border-radius: 0.5rem;
    margin: 2rem 0 4rem 0;
  `}
`;

const Column = styled(Flex)`
  width: calc(100% / 3);
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.medium`
    align-items: flex-start;
    flex-direction: row;
  `}
`;

const CopyIcon = styled(Box)`
  width: 3rem;
  height: 3rem;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;

  ${({ theme }) => theme.medium`
    margin-right: 1rem;
    text-align: right;
  `}
`;

const Copy = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
`;

const CopyHeader = styled(Flex)`
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  text-align: center;
  margin-top: 0.25rem;

  ${({ theme }) => theme.medium`
    font-size: 0.9rem;
    text-align: left;
    margin-top: 0;
  `}
`;

const CopyText = styled(Flex)`
  font-size: 0.8rem;
  display: none;

  ${({ theme }) => theme.medium`
    width: 70%;
    display: flex;
  `}
`;

const WhyRentWithRVShare = () => (
  <Container>
    <Column>
      <CopyIcon><img src={GlobeIcon} alt="Globe Icon" loading="lazy" width="30px" height="41px" /></CopyIcon>
      <Copy>
        <CopyHeader>Wide-Ranging Inventory</CopyHeader>
        <CopyText>From affordable pop-ups to luxury motorhomes</CopyText>
      </Copy>
    </Column>
    <Column>
      <CopyIcon><img src={RVIcon} alt="RV Icon" loading="lazy" width="44px" height="31px" /></CopyIcon>
      <Copy>
        <CopyHeader>Largest RV Rental Marketplace</CopyHeader>
        <CopyText>Thousands of 5 star reviews from happy customers</CopyText>
      </Copy>
    </Column>
    <Column>
      <CopyIcon><img src={LockIcon} alt="Lock Icon" loading="lazy" width="28px" height="35px" /></CopyIcon>
      <Copy>
        <CopyHeader>Secure and Most Trusted</CopyHeader>
        <CopyText>24/7 Emergency  roadside assistance on every booking</CopyText>
      </Copy>
    </Column>
  </Container>
);

export default WhyRentWithRVShare;
