import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button as BaseButton, Flex, Text } from 'core/ui';
import Icon from 'core/ui/Icon';

import useSearchFiltersLinkProps from '../../hooks/useSearchFiltersLinkProps';
import { SearchPagerShape } from '../../shapes/SearchResults';

const Button = styled(BaseButton)`
  background-color: #fff;
  font-size: 11px;
  padding: 9px 28px;

  &[disabled] {
    background-color: #f7f7f7;
    color: ${({ theme }) => theme.colors.lightGrey};
    cursor: default;
  }
`;

const BackArrowIcon = styled(Icon).attrs({
  size: 'lg',
  icon: 'chevron-left',
})``;

const NextArrowIcon = styled(Icon).attrs({
  size: 'lg',
  icon: 'chevron-right',
})``;

function Pagination(props) {
  const { pagination, pathname } = props;
  const [linkPrevPath, setLinkPrevPath] = useState(null);
  const [linkNextPath, setLinkNextPath] = useState(null);
  const createLinkProps = useSearchFiltersLinkProps();

  useEffect(() => {
    setLinkPrevPath({ ...createLinkProps(pagination?.previousPage, pathname) });
    setLinkNextPath({ ...createLinkProps(pagination?.nextPage, pathname) });
  }, [createLinkProps, pagination, pathname]);

  // Only show pagination if there's more than 1 page
  if (!pagination || pagination.pages.length <= 1) {
    return null;
  }

  return (
    <Flex justifyContent="center" alignItems="center" py="50px">
      <Button
        as="a"
        variant="blueBorder"
        disabled={!pagination.hasPreviousPage}
        data-id="pagination-previous"
        data-event-category="Search"
        data-event-action="Previous"
        data-event-label="Pagination"
        href={linkPrevPath?.href}
      >
        <span className="sr-only">Previous page</span>
        <BackArrowIcon aria-hidden="true" />
      </Button>

      <Text px="30px" fontWeight="normal" fontSize="14px">
        {pagination.resultsCountDisplay}
      </Text>

      <Button
        as="a"
        variant="blueBorder"
        disabled={!pagination.hasNextPage}
        data-id="pagination-next"
        data-event-category="Search"
        data-event-action="Next"
        data-event-label="Pagination"
        href={linkNextPath?.href}
      >
        <span className="sr-only">Next page</span>
        <NextArrowIcon aria-hidden="true" />
      </Button>
    </Flex>
  );
}

Pagination.propTypes = {
  pagination: PropTypes.shape(SearchPagerShape), // eslint-disable-line react/require-default-props
};

export default React.memo(Pagination);
