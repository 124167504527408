import React from 'react';
import styled from 'styled-components';

/* EXAMPLE DATA
const data = [
  { distance: 13, colors: ["#ffd847", "#e0a106"], label: 'highlighted-a' },
   // use same color[0] & color[1] for no gradient
  { distance:  9, colors: ["#000fff", "#000fff"], label: 'No-Gradient' },
  { distance: 20, colors: ["#ff47ab", "#e0064e"], label: 'highlighted-b' },
  // zero distance for empty column
  { distance: 0, colors: ["#ff47ab", "#e0064e"], label: 'empty-column' },
  { distance: 16, colors: ["#add9c0", "#1da890"], label: 'c' },
  { distance: 30, colors: ["#cbd9ad", "#7ca81d"], label: 'd' },
  { distance: 22, colors: ["#d9c1ad", "#714511"], label: 'e' },
];
*/

export const MainContainer = styled.div`
  border-bottom: 2px solid ${props => props.theme.colors.lightGrey};
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const BarContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 1px;
`;

export const Bar = styled.div`
  background-image: linear-gradient(
    to bottom,
    ${props => props.colors[0]},
    ${props => props.colors[1]}
  );
  height: ${(props) => props.height}%;
  margin-top: 10px;
  opacity: ${props => props.muted ? '0.5' : '1'};
  width: 100%;
`;

export default function BarGraph(props) {
  const { data = [] } = props;
  return (
    <MainContainer>
      {data.map(({ distance, colors, label, muted }) => {
        return (
          <BarContainer key={label} data-cy="BarGraph_BarContainer">
            <Bar height={distance * 2} colors={colors} muted={muted} />
          </BarContainer>
        );
      })}
    </MainContainer>
  );
}
