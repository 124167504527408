import React from 'react';
import styled from 'styled-components';

import mediaQuery from 'core/styles/mediaQuery';
import { Classes } from 'core/helpers/RV';
import ClassTypeIcon from 'search/components/SearchFilters/filters/ClassTypes/ClassTypeIcon';
import Icons from 'core/ui/Icons';

const SlimCardStyled = styled.a`
  flex: 0 1 33rem;
  text-align: center;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  text-decoration: none;
  position: relative;
`;

const RvIcon = styled(({ component }) => component)`
  height: 45px;
  width: auto;
  color: #3f3f3b;
`;

const ClassTitle = styled.h4`
  font-size: 22px;
  margin: 0;
  padding-bottom: 0.25rem;
  color: #0d63a6;
`;

const ClassCaption = styled.span`
  color: #414141;
  text-align: center;
  display: inline-block;
  padding: 0 7% 2.5rem 7%;

  ${mediaQuery.mobile`
    width: 100%;
    padding-bottom: 1.5rem;
  `}
`;

const ClassLink = styled.span`
  text-align: center;
  display: inline-block;
  padding-bottom: 0.75rem;
  color: #0d63a6;
  text-decoration: none;
  width: 85%;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  ${mediaQuery.betweenMediumLarge`
    padding: 0;
    width: 90%;
  `}
`;


const SlimCard = props => {
  const IconComponent = Object.values(Classes).includes(props.slug) ? ClassTypeIcon : Icons;

  // Grab just the city from the location
  const shortenedLocation = props.location.split(',')[0].trim();

  return (
    <SlimCardStyled data-testid="rv-class-type-cards" href={props.url}>
      <RvIcon component={<IconComponent type={props.slug} />} />
      <ClassTitle>{props.title} </ClassTitle>
      <ClassCaption>{props.caption}</ClassCaption>
      <br />
      <ClassLink>View all {props.title} rentals in {shortenedLocation}</ClassLink>
    </SlimCardStyled>
  );
};

export default SlimCard;
