import React from 'react';
import styled from 'styled-components';

import '@glidejs/glide/dist/css/glide.core.css';
import RVCard from 'search/components/RVCard';

const ResultsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const ResultWrapper = styled.div`
  display: flex;
  flex: 0 0 48% !important;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 48% !important;
  min-width: 150px;
  margin-bottom: 1rem;

  a {
    cursor: pointer;
  }

  h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > div {
    min-height: 280px;
  }

  @media (min-width: 992px) {
    flex: 0 0 23% !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 23% !important;
    max-width: 23%;
  }
`
const SearchLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;
`
const SearchLink = styled.a`
  text-decoration: none;
`

const buildCopyAndParams = (title, region) => {
  const transformedRegion = region.toLowerCase().replace(/, /g, '/').replace(/ /g, '-');

  switch (true) {
    case title.includes('Motorhome'):
      return [`/rv-rental/${transformedRegion}/motorhome`, `See All Motorhome RVs in ${region}`];
    case title.includes('Camper'):
      return [`/rv-rental/${transformedRegion}/class-b`, `See All Camper Van RVs in ${region}`];
    case title.includes('Trailer'):
      return [`/rv-rental/${transformedRegion}/travel-trailer`, `See All Travel Trailer RVs in ${region}`];
    case title.includes('Pet'):
      return [`/rv-rental/${transformedRegion}/pet-friendly`, `See All Pet Friendly RVs in ${region}`];
    default:
      return ['', `See All RVs Near ${region}`];
  }
}

export default function RegionalSearchResult({ title, results, region }) {
  const copyAndParams = buildCopyAndParams(title, region);

  return (
    <>
      <h2>{title}</h2>
      <ResultsGrid>
        {results.map((rv, i) => (
          <ResultWrapper key={rv.id} className="rv-card">
            <RVCard rv={rv} lazyLoad position={i + 1} isSingleImageCard />
          </ResultWrapper>
        ))}
        <SearchLinkWrapper>
          <SearchLink
            href={copyAndParams[0]}
            target="_blank"
            data-event-category="Regional Search Page"
            data-event-action={`${title} Search Link Click`}
            data-event-label={`${title} Search Link Click`}
          >
            {copyAndParams[1]}
          </SearchLink>
        </SearchLinkWrapper>
      </ResultsGrid>
    </>
  )
}
