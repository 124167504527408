import React from 'react';
import styled from 'styled-components';
import { connect as formikConnect } from 'formik';

import { Text } from 'core/ui';

import useSearchFilters from 'search/hooks/useSearchFilters';

import SliderWithInputsBargraph from 'components/SliderWithInputsBargraph';
import ApplyButtons from '../ApplyButtons';

const MIN_KEY = 'min';
const MAX_KEY = 'max';

const Wrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

function PriceFilter({
  filters,
  handleSaveValues,
  buckets,
  closeDropdown,
  formik: { values },
  showButtons = true,
}) {
  const { min: minPrice, max: maxPrice } = filters;
  const priceMin = buckets ? buckets[0].key : 0;
  const priceMax = buckets ? buckets[buckets.length -1].key : 500; // If no search results, then just set a high number as max

  const min = values[MIN_KEY] || minPrice;
  const max = values[MAX_KEY] || maxPrice;

  const data = !buckets ? [] : buckets.map((bucket => {
    return {
      distance: bucket.doc_count,
      colors: ["#91918e", "#91918e"],
      label: bucket.key,
      start: bucket.key,
      end: bucket.key
    }
  }));

  const { changeSearchFilters } = useSearchFilters();

  const handleSave = vals => {
    handleSaveValues({
      [MIN_KEY]: vals[0],
      [MAX_KEY]: vals[1],
    });
  }

  const submitFilters = () => {
    closeDropdown();
    changeSearchFilters({ min: values?.min, max: values?.max });
  };

  return (
    <Wrapper>
      <SliderWithInputsBargraph
        fieldKeyMin={MIN_KEY}
        fieldKeyMax={MAX_KEY}
        defaultMin={priceMin}
        defaultMax={priceMax}
        step={1}
        handleSaveValues={handleSave}
        handleSubmit={submitFilters}
        min={min}
        max={max}
        barData={data}
        sliderLabel='Price'
        srTextMax="adjust maximum price"
        srTextMin="adjust minimum price"
      >
        {handleClear => {
          if (!showButtons) {
            return null;
          }

          return (
            <Text mt="30px" textAlign="right">
              <ApplyButtons handleClear={handleClear} closeDropdown={submitFilters} />
            </Text>
          );
        }}
      </SliderWithInputsBargraph>
    </Wrapper>
  );
}

export default React.memo(formikConnect(PriceFilter));
