/* eslint-disable no-param-reassign */

import React, { useCallback } from 'react';
import { connect as connectFormik } from 'formik';

import { Flex } from 'core/ui';
import ResponsiveRender from 'components/ResponsiveRender';
import ExplanationPopover from 'components/ExplanationPopover2';
import SafeTracking from 'tracking/SafeTracking';

import {
  Title,
  Ul,
  Li,
  Label,
  HiddenCheckbox,
  ButtonClassType,
  Description,
  ButtonWrapper,
  MobileCheckbox,
  SelectAllButton,
} from './styles';
import ClassTypeIcon from './ClassTypeIcon';
import ApplyButtons from '../ApplyButtons';

const trackSelectAll = (filterName) => {
  SafeTracking.track('Search Filter Used',
  {
    filterName,
    filterValueSelected: 'All',
    minValue: '',
    maxValue: '',
  },
  { rvsIntegrations: { all: false, segment: true } });
}


function ClassTypeFilter({ title, options, formik, closeDropdown }) {
  const handleToggleAll = useCallback(
    (setSelected = true) => {
      if (setSelected) {
        trackSelectAll(`${title} RV`);
      }
      const values = options.reduce((accum, option) => {
        accum[`${option.searchValue || option.value}`] = setSelected;
        return accum;
      }, {});

      formik.setValues({
        ...formik.values,
        rvClass: {
          ...formik.values.rvClass,
          ...values,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik, options],
  );

  const handleClear = useCallback(() => {
    handleToggleAll(false);
  }, [handleToggleAll]);

  return (
    <ResponsiveRender>
      {isMobile => (
        <>
          <Flex justifyContent={{ md: 'space-between' }} alignItems="center" mb={{ md: '10px' }}>
            <Title>{title}</Title>
            <SelectAllButton
              type="button"
              variant="linkPrimary"
              fontSize="14px"
              onClick={handleToggleAll}
            >
              Select All
            </SelectAllButton>
          </Flex>

          <Ul>
            {options.map((option) => {
              const name = `rvClass.${option.searchValue || option.value}`;

              return (
                <Li key={option.id}>
                  {isMobile ? (
                    <>
                      <MobileCheckbox name={name}>{option.label}</MobileCheckbox>
                      <ExplanationPopover
                        buttonStyle={{ padding: '8px 11px', marginTop: '3px' }}
                        content={option.description}
                        trigger="mouseenter focus click"
                      />
                    </>
                  ) : (
                    <>
                      <Label htmlFor={option.id}>
                        <HiddenCheckbox name={name} id={option.id} />

                        <ButtonClassType type="button" variant="blueBorder">
                          <ClassTypeIcon type={option.id.toUpperCase()} />
                          <div>{option.label}</div>
                        </ButtonClassType>
                      </Label>

                      <Description>{option.description}</Description>
                    </>
                  )}
                </Li>
              );
            })}
          </Ul>

          {!isMobile && (
            <ButtonWrapper>
              <ApplyButtons handleClear={handleClear} closeDropdown={closeDropdown} />
            </ButtonWrapper>
          )}
        </>
      )}
    </ResponsiveRender>
  );
}

export default connectFormik(React.memo(ClassTypeFilter));
