import React from 'react';
import { connect as formikConnect } from 'formik';

// import ClassTypeIcon from './ClassTypes/ClassTypeIcon';
import {
  // ButtonClassType,
  // ButtonLabel,
  Grid,
  GridButtonColumn,
  GridColumn,
  GridHeading,
  // HiddenCheckbox,
  Row,
  StyledCheckboxNoLabel
} from './filterStyles';

function RvTypeFilter({ drivableOptions, towableOptions, isMobile = false }) {
  const IconButton = (option, towable = false) => {
    const name = `rvClass.${option.searchValue || option.value}`;

    return (
      <GridButtonColumn key={option.id} multiRow={isMobile} towable={towable}>
        <StyledCheckboxNoLabel name={name}>
          {option.label}
        </StyledCheckboxNoLabel>
        {/* This is what Product wanted but it breaks in Firefox. Saving for later.
        <ButtonLabel htmlFor={name}>
          <HiddenCheckbox name={name} />

          <ButtonClassType type="button" variant="blueBorder">
            <ClassTypeIcon type={option.id.toUpperCase()} />
            <div>{option.label}</div>
          </ButtonClassType>
        </ButtonLabel> */}
      </GridButtonColumn>
    )
  };

  return (
    <Grid noFlex data-testid="filters-rental-rules-checkboxes">
      <GridColumn noFlex>
        <GridHeading>Drivable</GridHeading>
        <Row>
          {drivableOptions.map((option) => {
            return IconButton(option)
          })}
        </Row>
      </GridColumn>
      <GridColumn>
        <GridHeading>Towable</GridHeading>
        <Row wrap="true">
          {towableOptions.map((option) => {
            return IconButton(option, true)
          })}
        </Row>
      </GridColumn>
    </Grid>
  );
}

export default React.memo(formikConnect(RvTypeFilter));
