import React from 'react';
import styled from 'styled-components';

import { Button as Base } from 'core/ui';

const Button = styled(Base)`
  font-size: 14px;
`;

function ApplyButtons({
  applyText='Apply',
  applyVariant='greyBorder',
  clearText='Clear',
  clearVariant='linkPrimary',
  closeDropdown,
  handleClear,
  showClear = true,
}) {

  return (
    <>
      {showClear && (
        <Button type="button" variant={clearVariant} mr="15px" onClick={handleClear}>
          {clearText}
        </Button>
      )}
      <Button type="button" variant={applyVariant} onClick={closeDropdown}>
        {applyText}
      </Button>
    </>
  );
}

export default React.memo(ApplyButtons);
