import React from 'react';
import { connect as formikConnect } from 'formik';

import { CheckboxWrapper, Row, StyledCheckboxNoLabel } from './filterStyles';

function MiscFilter({ handleFormikSaveValues, amenity, modifier }) {

  const handleSaveValues = e =>
    handleFormikSaveValues({
      [e.currentTarget.name]: e.currentTarget.checked,
    });

  return (
    <Row data-testid="filters-electric-service-checkboxes">
      <CheckboxWrapper key={amenity}>
        <StyledCheckboxNoLabel name={`amenities.${amenity}`} onChange={handleSaveValues}>
          RV has {modifier && `${modifier} `}{amenity}
        </StyledCheckboxNoLabel>
      </CheckboxWrapper>
    </Row>
  );
}

export default React.memo(formikConnect(MiscFilter));
