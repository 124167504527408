import React from 'react';
import { getTotalResultsDisplay } from 'core/helpers/paginate';

const variationOne = (destination, total) => (
  <>
    <p>
      There’s nothing like taking to the open road on a trip to {destination} in a motorhome! Traveling in an
      RV gives you flexibility most other forms of travel don’t. If you’re in a spot you love, you
      can linger longer. If you’re not thrilled with your spot for the night, you can pack up and
      move on the next day. Traveling in a motorhome allows you to see the country, and {destination} in a way
      that many people don’t have an opportunity to experience.
    </p>
    <p>
      The fact that you don’t own an RV doesn’t need to stop you, either! Renting a motorhome or
      camper can be a great introduction to RV life. If you rent with RVshare, you’ll have 24-hour
      roadside assistance available to you, with someone on-hand to answer any questions you have at
      any time.
    </p>
    <p>
      Browse {total} roomy Class A RVs and motorhomes, smaller Class B campervans, or Class C campers
      that are the perfect middle ground between the two. {destination} also has 5th wheels, toy haul trailers
      if you want to bring along ATVs, boats, or other fun equipment, and so much more.
    </p>
    <p>
      While you’re considering a camper rental, keep in mind that many renters also offer a discount
      the longer you rent. If you’re renting a motorhome for a week-long, or month-long, trip, your
      cost per night could end up being less than the price listed on the main page. Simply click on
      the vehicle you’ve chosen, and scroll down to see weekly and monthly rental rates for that
      vehicle.
    </p>
    <p>
      You can search for the perfect option for you, and narrow your search by price, RV type, or
      year of vehicle. You can look for vehicles that are pet-friendly. You can even choose between
      a one-way rental that you drop off at your destination, or a round-trip rental. If you don’t
      want to drive all the way from your home to {destination}, you can also consider flying into town and
      renting an RV there to use on your trip.
    </p>
    <p>
      As you plan your trip, be sure to check our top picks here to help you decide on your
      activities. Whether you’re looking for an outdoor getaway, a trip steeped in the area’s
      history, or an upscale vacation with world-class dining and shopping, there’s something for
      everyone in our guide! Get suggestions on nearby national and state parks to visit and
      campgrounds where you can stay close by. Also, get guidance on local restaurants, art
      galleries, and shopping - you’re sure to find something to interest you! Be sure to check the
      Events &amp; Entertainment section for live music, sporting events, festivals, and other timely
      events while you’re in town. Our practical help includes ideas on road trips starting in {destination},
      and offers information on RV Dump Stations to help you with facilities while you’re there.
    </p>
    <p>Happy trails!</p>
  </>
);

const variationTwo = (destination, total) => (
  <>
    <p>
      Exploring {destination} in an RV is a wonderful way to travel! You can spend your day seeing the sights,
      learning about the local history, and admiring the breathtaking scenery and know that you get
      to retreat to your own bed every evening. You can eat at delicious local restaurants...but if
      you need a break, you can also make yourself a nice home-cooked meal in the comfort of your
      motorhome. You also have the freedom of knowing you can stay longer if you find a spot you
      like, or pack up and head out if you feel the need to move on. It’s like having the comforts
      of home, but also being able to experience new adventures!
    </p>
    <p>
      You don’t need to worry if you don’t have your own RV, either. In fact, even if you’re
      considering purchasing one, renting one through RVshare first and going on a trip is a good
      way to do a trial run. Renting with RVshare is a safe experience because even if you’re an
      RVing novice, they have 24-hour roadside assistance to help you with any questions or
      problems.
    </p>
    <p>
      Now that you’ve decided to visit {destination}, you just have to decide which kind of camper you want to
      take on your journey! Class A motorhomes are the large bus-type RVs you see on the road -
      perfect for larger groups or people who prefer amenities like bathrooms, showers,
      kitchens...some even come with laundry facilities or fireplaces! Class B campervans are great
      for couples or a very small family, and the fact that they’re more compact makes them easier
      to handle. Class C campers are a good compromise between the two - larger than a campervan and
      with some perks like bathrooms, but not as unwieldy to drive as a Class A. RVshare also has
      tow vehicles like 5th wheels and toy haul trailers if you like to bring dirt bikes, kayaks, or
      other fun gear on your trips!
    </p>
    <p>
      While you’re browsing RVshare, also consider that most owners will offer you a break the
      longer you rent their vehicle. If you click on the camper you’re interested in and scroll
      down, it will show you weekly and monthly rental rates.
    </p>
    <p>
      RVshare also lets you narrow your search by what’s important to you. Currently in {destination}, we
      have {total} rentals giving you the options to search by location, size or type of camper, or even year
      of vehicle. You can also search for pet-friendly RVs. There are also options for one-way
      rentals if you’re planning to fly home from your destination, or round-trip rentals.
    </p>
    <p>
      While you’re making your plans to explore {destination} be sure to check out our recommendations on this
      page! From local or national parks in the area to historic sites and museums to the best place
      to grab lunch, we have lots of trip-planning advice to help you on your journey. Our Events &amp;
      Entertainment section will help you see the sporting events, festivals, live music, and other
      events going on while you’re visiting. We also have guides to practical things like where to
      camp for the night, where you can find local dump stations, and where you might like to plan
      road trips that originate in {destination}.
    </p>
    <p>
      You’re sure to find plenty of suggestions for things to do and help for camping and caring for
      your RV while you’re in the area.
    </p>
  </>
);

const variationThree = (destination, total) => (
  <>
    <p>
      Traveling the country and visiting {destination} in an RV is a great way to spend a vacation! You have
      the flexibility to tailor your trip to suit your needs on any given day. You can stay longer
      in a spot you really like, or pack up and move on if you’re feeling antsy. You can even take a
      detour from your planned trip if you see something interesting and want to take a side journey
      to investigate. RVing is also a great way to travel with kids, who tend to like routines. They
      can get out and experience new things during the day, but go to sleep in the same spot they’re
      familiar with each night. You also have a home base to retreat if they need to nap or just
      take a break for a while.
    </p>
    <p>
      If you’re interested in RV travel but don’t have your own, that’s no problem! You can rent one
      with RVshare. In fact, we have over {total} RV Rentals in {destination} for you to choose from! Even if
      you’re considering purchasing one, you may want to rent through RVshare first to try it out.
      Finally, if you’ve never taken a vacation with a camper or motorhome before, RVshare is a
      great way to take a first trip. You’re covered by their insurance, and they have 24-hour
      roadside assistance so if you have any trouble at all, you can call and get help.
    </p>
    <p>
      RVshare’s website also offers you a variety of motorhomes, campers, and trailers to choose
      from for your trip. If you’re traveling with a large group or want things like showers,
      bathrooms, and entertainment centers with comfy chairs, a Class A motorhome will be right for
      you. If you’re traveling solo or as a couple, a Class B campervan may be all you need, and the
      fact that they’re smaller makes them easier to handle and able to fit almost any camping spot.
      A Class C camper is right in between those two - larger than a campervan and with some
      amenities of a Class A motorhome without being quite as big as that. You can also rent 5th
      wheel trailers, teardrop campers, toy haulers, and a variety of other trailers to stay in as
      well.
    </p>
    <p>
      One more thing to keep in mind while you’re budgeting for your trip is that many owners will
      offer lower rates the longer you rent a vehicle. You can see the daily, weekly, and monthly
      rates for renting each vehicle by clicking on the camper you’re interested in, then scrolling
      down where you’ll find a price breakdown. The cost per day should go down the longer you rent.
    </p>
    <p>
      You can also search by other factors that are important to you. Search by size or type of
      vehicle, price, and even by the year the camper was made. You can search for pet-friendly
      motorhomes, and for one-way or round-trip rentals.
    </p>
    <p>
      As you’re planning your trip, be sure to check our suggestions here on this page. We’ve
      rounded up the closest national and state parks you might enjoy, along with historic sites and
      other things to do in the area. The Events &amp; Entertainment section has a timely list of
      sports, music, festivals, and other events happening in the area and you can search by the
      dates when you’ll be visiting.
    </p>
    <p>
      There’s also helpful information on where to camp, available dump stations, and road trips you
      can plan with {destination} as your starting point. You’re sure to find plenty of ideas for where to go
      and what to do while you’re in the area!
    </p>
  </>
);

const variationFour = (destination, total) => (
  <>
    <p>
      Visiting {destination} in an RV is a wonderful way to explore! You can spend a day seeing the sights and
      learning about the area, and retire to your motorhome in the evening. After a day on the town,
      you can relax in your own space with your things and sleep in your own bed. You also have the
      freedom to adjust your trip to your wishes - if you’re in a place you’d like to spend more
      time, you can! If you’re ready to move on a little early, you have the flexibility to leave
      when you’d like.
    </p>
    <p>
      If you don’t have your own motorhome, but are interested in taking a trip in one, RVshare can
      help. In fact, if you’re interested in purchasing a motorhome, renting one through RVshare
      first can be a great way to see if it’s a good fit for you. Whether you’re an RVing pro or
      this is your first trip, you can rent with RVshare knowing you’re in good hands. The 24-hour
      roadside assistance means that even if you don’t have a lot of experience with motorhomes,
      someone is on-hand at all times to answer any questions or help you with whatever you need.
    </p>
    <p>
      With over {total} rentals in {destination}, you can also choose from a wide variety of campers, RVs, and
      trailers to take on your vacation. If you’re used to traveling with amenities like showers,
      laundry, and sometimes even fireplaces, a Class A motorhome is going to suit you well. If you
      want to travel lighter, and with just one or two other people, a Class B campervan might be
      more your style - the smaller size means it’s easier to maneuver and you can camp just about
      anywhere. A Class C camper is the perfect blend of both of those classes - larger than a
      campervan, but with some of the amenities of a Class A motorhome. RVshare also has 5th wheel
      trailers, teardrop campers, toy haulers, and a variety of campers and trailers to rent.
    </p>
    <p>
      Also, when you’re planning your trip, it’s helpful to know that most owners offer lower
      nightly rates the longer you rent your RV. If you rent for a week, or a month, you may get a
      lower rate per day for your trip. You can see the discount by clicking on the vehicle you’re
      interested in and scrolling down to the price breakdown where it will give you weekly and
      monthly rates.
    </p>
    <p>
      You can also search for an RV to rent using other indicators like size, type of vehicle,
      price, or even the year the camper was built. You can look for pet-friendly motorhomes, or for
      motorhomes to take one-way or round-trip.
    </p>
    <p>
      Next, be sure to check out our recommendations on this page for things to do and other helpful
      tips. We share the closest national and state parks to visit, along with historic sites and
      places of interest you may enjoy. The Events &amp; Entertainment section lists live music,
      festivals, sporting events and other things going on while you’ll be in town.
    </p>
    <p>
      We’ve also rounded up practical tips on where to camp, available dump stations, and road trips
      you can take starting in {destination}, all in one convenient spot! You’re sure to find something to do
      on your visit here.
    </p>
  </>
);

const variationFive = (destination, total) => (
  <>
    <p>
      An RV vacation to {destination} is an exciting way to spend a trip and make new memories! You’ll love
      having your own space - after a day of exploring the area, you can come back to your own home
      each night, with your own comfortable bed and things you enjoy. You can also travel on your
      own timeline - if you’re somewhere that you’d like to spend more time, you can stay longer. If
      you’re ready to move on...well, you can! Traveling by RV gives you a flexibility that you
      can’t find on just any trip.
    </p>
    <p>
      You also don’t need to own your own motorhome to enjoy an RV vacation. Rent an RV with
      RVshare, and you’ll have all the perks of RV ownership without any of the problems. If you’re
      interested in buying an RV, it’s still a good idea to rent one with RVshare first to see if
      you enjoy it and if it’s a good fit for you and your lifestyle. If you’re brand-new to RVing,
      renting with RVshare is also a good idea, because their 24-hour roadside assistance means
      someone is on-hand all the time to answer any questions you may have.
    </p>
    <p>
      RVshare also lets you choose from a variety of vehicles so you can pick the one that best
      suits your needs. Traveling with a large group or with people who want bathrooms and showers?
      You likely want a Class A motorhome for your journey. If you’re willing to forgo a few perks
      and are traveling with a very small group, a Class B campervan may be all you need. Finally, a
      Class C camper is a good mix of both other options - it’s not as large as a Class A motorhome,
      but it has more amenities than a Class B camper. RVshare also has A-frame trailers, 5th
      wheels, teardrop trailers, and a host of other options for camping and enjoying the outdoors.
    </p>
    <p>
      Keep in mind that while you’re budgeting for your trip, many owners also offer discounts the
      longer you rent. So a per-night fee is going to be higher for one night than it will if you
      rent it for a week, or for a month. You can see the discount by clicking on the vehicle you’re
      interested in renting, then scrolling down to the breakdown that shows you the price for
      renting for a week or for a full month.
    </p>
    <p>
      With over {total} RV Rentals in {destination}, you can narrow your search for an RV by searching by area, or
      price, size, type of vehicle, or even the year the camper was built. They have a function to
      search for pet-friendly RVs, or for RVs to rent for a one-way journey or a round-trip.
    </p>
    <p>
      As you plan your vacation, be sure to check out the activities and other suggestions we linked
      to on this page. We’ve researched the closest national and state parks, the best campgrounds
      in the area, and the fun things to do while you’re staying there. We’ve rounded up the events
      going on while you’re in town, and entertainment options for you all over the city. We also
      share practical tips like where to find dump stations, or how to plan a road trip leaving from
      {destination} so you can enjoy the surrounding area as well.
    </p>
    <p>Whatever your interests, we’re sure you’ll find something on this page to keep you busy!</p>
  </>
);

const variationSix = (destination, total) => (
  <>
    <p>
      You’re sure to enjoy yourself on an RV trip to {destination}. RV vacations are a great way to make new
      memories, and {destination} has many opportunities to do just that. You’ll also appreciate having your
      own space at the end of the day - spend your time adventuring, and then return to a familiar,
      comfortable spot to unwind and sleep in your own bed each night. A motorhome vacation also
      allows you to travel on your own schedule. If you’re in a spot you really enjoy, you can stay
      longer. If you’re feeling antsy and ready to move on - you can! The flexibility of motorhome
      travel is one of the great perks of this kind of vacation.
    </p>
    <p>
      Don’t worry if you don’t own an RV either - RVshare can help. When you rent a motorhome with
      RVshare, you get all the fun of owning an RV without any of the worries. If you’re a new RVer,
      you can rent with RVshare knowing that their 24-hour roadside assistance means you always have
      someone at your disposal to answer any questions you might have while on the road. You can
      even look for RVs that offer setup and the owner will come and set up your RV at your campsite
      for you.
    </p>
    <p>
      You have many vehicles to choose from when you rent with RVshare as well. If you’re traveling
      with a lot of people, or want your own shower and bathroom on the road, a Class A motorhome
      will likely fit you best. If you’re traveling light, and either solo or with one other person,
      a Class B motorhome may be all you need. A Class C camper could also be the perfect fit for
      you - it’s a good combination of the first two classes - smaller than a Class A motorhome, but
      with more perks and appliances than a Class B campervan. You can also choose from 5th wheel
      trailers, teardrop or A-frame trailers, toy haulers, and more.
    </p>
    <p>
      As you plan your trip, also keep in mind that most owners give discounts the longer you rent.
      Renting for one night will be more per night than renting for a week, which will be more per
      night than renting for a month. You can find these discounts by clicking on the vehicle you
      like, then scrolling down to the price breakdown on the vehicle page.
    </p>
    <p>
      With over {total} RV Rentals in {destination}, you can narrow your search on RVshare and search for a vehicle
      by location, by price, by size, or by the year of the model. You can also search for
      pet-friendly RVs, and for RVs you would rent for a one-way trip or a round-trip.
    </p>
    <p>
      We’ve included some helpful links on this page to a variety of activities in the area and
      other information you may need on your trip. Check out the Events &amp; Entertainment section for
      live music, festivals, and sports games that will happen while you’re in town. You can also
      find interesting places to visit in the area year-round. We also have links to popular
      campgrounds, nearby dump stations, and road trips you can take starting here in {destination} so you can
      see more of the state.
    </p>
    <p>
      No matter how you choose to enjoy yourself, you’re sure to make memories here to last a
      lifetime!
    </p>
  </>
);

const variationSeven = (destination, total) => (
  <>
    <p>
      A road trip in an RV to {destination} is a great way to make new memories with family and friends! In
      fact, traveling in an RV is a wonderful way for families to vacation. Kids who like routine
      are able to spend the day exploring and then come back to a familiar bed and have a comforting
      end to their day. An RV trip also allows you to travel on your own time. If you’re having a
      great time in one area, you can stay longer. If you’re ready to move on, you can pack up and
      go. A motorhome vacation allows you flexibility that many other trips do not.
    </p>
    <p>
      If you don’t own your own RV, that’s easy to fix, too! RVshare rents a variety of vehicles,
      and you can have all the fun of using an RV without the worries of owning it. If you are
      interested in owning one, renting with RVshare first allows you to try one out to see if it
      fits with your preferences and lifestyle before you commit to buying one. Also, if this is
      your first RV trip, you can rent with RVshare and have the assurance that their 24-hour
      roadside assistance means you can call someone at any hour of the day with questions or
      problems.
    </p>
    <p>
      You have a variety of options ({total} in fact) for campers and motorhomes when you rent with
      RVshare. Class A motorhomes are a good choice for large groups or for people who like some
      room and some perks like showers and even laundry. Class B campervans are a better choice for
      people traveling alone or with a very small group - their smaller size makes them great for
      managing windy roads and they can fit almost any campsite. Class C campers are a good
      compromise between the two - they’re larger than campervans but they have some of the
      amenities of a Class A motorhome. RVshare also rents 5th wheel trailers, teardrop and A-frame
      trailers, toy haulers, and much more. You’re sure to find something to suit your needs!
    </p>
    <p>
      Also remember as you plan that most owners give discounts for longer rental periods. Your
      nightly rate will likely go down if you rent for a week, or a month. You can see what owners
      are charging by clicking on the vehicle you’re interested in, then scrolling down to the price
      breakdown to see how much they charge per night, per week or per month.
    </p>
    <p>
      Search for a vehicle on RVshare by location, price, size, or year of model. You can also
      narrow your search by whether a camper is pet-friendly. You can search for vehicles available
      to rent for a one-way journey or a round-trip.
    </p>
    <p>
      On this page, you’ll also find helpful tips for planning your stay. See campgrounds we
      recommend, find local state parks or national parks in the state. Browse the Events &amp;
      Entertainment section for ideas on where to eat and what to do in town and see a list of live
      events happening while you’re visiting. You’ll also find important advice on where dump
      stations are located, and tips to plan a road trip starting right here in {destination} to explore the
      surrounding area.
    </p>
    <p>
      No matter what activities you plan on your trip, you’ll be making memories that will last long
      after you’ve returned home!
    </p>
  </>
);

const variationFunctions = [
  variationOne,
  variationTwo,
  variationThree,
  variationFour,
  variationFive,
  variationSix,
  variationSeven,
];

const RandomRegionalCopy = ({ destination, total, location, discoverTitle }) => {
  // const variationFunction = variationFunctions[Math.floor(Math.random() * variationFunctions.length)];
  let variationIndex = Math.abs(location.lat + location.lng)

  while (variationIndex > 6) {
    variationIndex /= destination.length
  }

  const variationFunction = variationFunctions[Math.floor(variationIndex)]
  const roundedTotal = getTotalResultsDisplay(total);

  return (
    <div>
      <h2>{discoverTitle || `Discover ${destination} in an RV!`}</h2>
      {variationFunction(destination, roundedTotal)}
    </div>
  );
};

export default RandomRegionalCopy;
