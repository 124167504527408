import React from 'react';
import { connect as formikConnect } from 'formik';

import { CheckboxWrapper, Grid, GridColumn, GridHeading, Row, StyledCheckboxNoLabel } from './filterStyles';

function AmenitiesFilter({ handleFormikSaveValues }) {
  const handleSaveValues = e =>
    handleFormikSaveValues({
      [e.currentTarget.name]: e.currentTarget.checked,
    });

  const amenity = (name, label) => {
    return (
      <Row>
        <CheckboxWrapper>
          <StyledCheckboxNoLabel name={name} onChange={handleSaveValues}>
            {label}
          </StyledCheckboxNoLabel>
        </CheckboxWrapper>
      </Row>
  )};

  return (
    <>
      <GridHeading>Kitchen</GridHeading>
      <Grid data-testid="filters-kitchen-amenities-checkboxes">
        <GridColumn>
          {amenity('amenities.Microwave', 'Microwave')}
          {amenity('amenities.Slide Out', 'Slide out')}
        </GridColumn>
        <GridColumn>
          {amenity('amenities.Range (Stove)', 'Range (stove)')}
          {amenity('amenities.Kitchen Sink', 'Kitchen Sink')}
        </GridColumn>
        <GridColumn>
          {amenity('amenities.Refrigerator', 'Refrigerator')}
        </GridColumn>
      </Grid>
      <GridHeading>Bathroom</GridHeading>
      <Grid data-testid="filters-bathroom-amenities-checkboxes">
        <GridColumn>
          {amenity('amenities.Shower', 'Shower')}
        </GridColumn>
        <GridColumn>
          {amenity('amenities.Bathroom Sink', 'Bathroom Sink')}
        </GridColumn>
        <GridColumn>
          {amenity('amenities.Toilet', 'Toilet')}
        </GridColumn>
      </Grid>
      <GridHeading>Other</GridHeading>
      <Grid data-testid="filters-other-amenities-checkboxes">
        <GridColumn>
          {amenity('amenities.In Dash Air Conditioning', 'In Dash Air Conditioning')}
        </GridColumn>
        <GridColumn>
          {amenity('amenities.Rear Vision Camera', 'Rear Vision Camera')}
        </GridColumn>
        <GridColumn>
          {amenity('amenities.TV', 'TV')}
        </GridColumn>
      </Grid>
    </>
  );
}

export default React.memo(formikConnect(AmenitiesFilter));
