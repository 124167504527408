import React from 'react';
import get from 'just-safe-get';

import ResponsiveRender from 'components/ResponsiveRender';
import Dropdown from 'components/DropdownCustom';

import getSelectedText from './getSelectedText';
import ClassTypeFilter from './ClassTypeFilter';
import VehicleTowingMatch from '../VehicleTowingMatch';

function ClassDropdown({ filters: { rvClass }, options, title }) {
  const selectedText = getSelectedText(options, rvClass);

  return (
    <ResponsiveRender>
      {isMobile =>
        isMobile ? (
          <ClassTypeFilter options={options} title={title} />
        ) : (
          <Dropdown
            button={`${title} RV${selectedText}`}
            buttonActive={!!selectedText}
            id={title}
            dropdownStyles={{ minWidth: '330px' }}
          >
            {({ handleToggle }) => (
              <>
                {title === 'Towable' ? (
                  <VehicleTowingMatch
                    options={options}
                    title={title}
                    closeDropdown={handleToggle}
                  />
                ) : (
                  <ClassTypeFilter options={options} title={title} closeDropdown={handleToggle} />
                )}
              </>
            )}
          </Dropdown>
        )
      }
    </ResponsiveRender>
  );
}

function propIsEqual(prevProps, nextProps, path) {
  return get(prevProps, path) === get(nextProps, path);
}

function arePropsEqual(prevProps, nextProps) {
  return prevProps.options.every(({ value, searchValue }) =>
    propIsEqual(prevProps, nextProps, `filters.rvClass.${searchValue || value}`)
  );
}

export default React.memo(ClassDropdown, arePropsEqual);
