import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'just-is-empty';

import usePrevious from 'core/hooks/usePrevious';
import { withTravelPreferences } from 'core/state/Globals';
import { parse as parseQuery, stringify as stringifyQuery } from 'core/helpers/Query';

import {
  buildSearchQuery,
  getFiltersFromQueryString,
  calculateNewLocation,
  isSame as isSameFilters,
} from '../helpers/Filters';
import useSearchFiltersManager, { SearchFiltersContext } from '../hooks/useSearchFiltersManager';

// Run once on component mount to ensure URL matches initial filters
const useUrlRebase = (history, initialFilters, isRegionalLander) => {
  useEffect(() => {
    const newQuery = buildSearchQuery(initialFilters, { addQueryPrefix: true });
    // do not augment the url if the url matches the server state
    // (or if it is the `/rv-search` path)
    if (
      history.location.search === newQuery ||
      history.location.pathname.includes('/rv-search') ||
      initialFilters.isTopicalSearchPage ||
      // do not add query string params to the url on initial load of lander pages
      (isRegionalLander && !history.location.search)
    ) {
      return;
    }

    const initialParams = parseQuery(history.location.search, { camelCase: true });
    const newParams = parseQuery(newQuery, { camelCase: true });
    let params;

    if (
      Object.keys(initialParams).length === 0 ||
      (Object.keys(initialParams).length === 1 && Object.keys(initialParams).includes('page'))
    ) {
      params = initialParams;
    } else {
      params = {
        ...initialParams,
        ...newParams,
      };
    }

    const newLocation = {
      ...history.location,
      search: stringifyQuery(params, { snakeCase: true }),
    };

    // Replace the location while maintaining existing query string params
    history.replace(newLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// watch travel preferences for external changes
const useTravelPreferencesWatcher = (travelPreferences, onChange) => {
  useEffect(() => {
    onChange(travelPreferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelPreferences]);
};

// watch for url changes
const useHistoryWatcher = (history, onChange) => {
  useEffect(() => {
    const unlisten = history.listen(onChange);

    return () => unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, onChange]);
};

// update url when filters change
const useHistoryUpdater = (history, filters, isBrowserNavigating, setIsBrowserNavigating) => {
  const prevFilters = usePrevious(filters);

  useEffect(() => {
    // user is navigating with browser buttons
    // `isBrowserNavigating` is b/c even when we change the filters for the first time,
    // the history.action is still 'POP' b/c we haven't pushed to it yet
    if (history.action === 'POP' && isBrowserNavigating) {
      setIsBrowserNavigating(false);
      return;
    }

    // do nothing if filters didn't change
    if (!prevFilters || isSameFilters(prevFilters, filters)) {
      return;
    }

    const newLocation = {
      ...history.location,
      ...calculateNewLocation(prevFilters, filters),
    };

    if (filters.isTopicalSearchPage) {
      /*
       * A "topical search" page has an RV class or category appended to the URL, e.g., rv-rentals/ohio/motorhome
       * If a user starts on a topical search page, and then adjusts the RV class filter, we accept those changes,
       * but kick you back up to "fallbackUrl", which is the regional lander page (rv-rentals/ohio in this case),
       * because the class/category in the URL doesn't apply any more.
       */
      if (filters.rvClass !== prevFilters.rvClass) {
        newLocation.pathname = filters.fallbackUrl;
      }
    }

    // If pathname changes, we want to reload the page
    if (newLocation.pathname !== history.location.pathname) {
      window.location.href = history.createHref(newLocation);
    } else {
      history.push(newLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
};

// ugly, but the easiest way to compare if we need to change the header stuff
const maybeSetTravelPreferences = (oldFilters, newFilters, setTravelPreferences) => {
  const oldDestination = buildSearchQuery({ destination: oldFilters.destination });
  const newDestination = buildSearchQuery({ destination: newFilters.destination });

  const oldTravelDates = buildSearchQuery({ travelDates: oldFilters.travelDates });
  const newTravelDates = buildSearchQuery({ travelDates: newFilters.travelDates });

  const newPrefs = {};

  if (oldDestination !== newDestination) {
    newPrefs.destination = newFilters.destination;
  }

  if (oldTravelDates !== newTravelDates) {
    newPrefs.travelDates = newFilters.travelDates;
  }

  if (oldFilters.sleeps !== newFilters.sleeps) {
    newPrefs.numberOfTravelers = newFilters.sleeps;
  }

  // Only call if preferences need to be updated
  if (!isEmpty(newPrefs)) {
    setTravelPreferences(prefs => ({
      ...prefs,
      ...newPrefs,
    }));
  }
};

function SearchFiltersProvider(props) {
  const {
    initialFilters,
    history,
    travelPreferences,
    setTravelPreferences,
    children,
    isRegionalLander,
  } = props;
  const searchFiltersContext = useSearchFiltersManager(initialFilters);

  const { filters, updateFilters, replaceFilters } = searchFiltersContext;

  const onTravelPreferencesChange = useCallback(
    newTravelPreferences => {
      updateFilters({
        destination: newTravelPreferences.destination,
        travelDates: newTravelPreferences.travelDates,
        sleeps: newTravelPreferences.numberOfTravelers,
        towingVehicle: newTravelPreferences.towingVehicle,
        distance:
          newTravelPreferences.destination?.location === 'Near By' ? filters.distance : null,
        rvshareMode: newTravelPreferences.destination?.location === 'Near By' ? false : null,
      });
    },
    [updateFilters]
  );

  const [isBrowserNavigating, setIsBrowserNavigating] = useState(false);
  const onHistoryChange = useCallback(
    (location, action) => {
      // POP action means the user is navigating with back/forward buttons
      if (action === 'POP') {
        setIsBrowserNavigating(true);

        const newFilters = getFiltersFromQueryString(location.search);

        // Update the filter state
        replaceFilters(newFilters);

        // set the travel preferences if they changed
        maybeSetTravelPreferences(filters, newFilters, setTravelPreferences);
      }
    },
    [filters, replaceFilters, setTravelPreferences]
  );

  useUrlRebase(history, initialFilters, isRegionalLander);
  useTravelPreferencesWatcher(travelPreferences, onTravelPreferencesChange);
  useHistoryWatcher(history, onHistoryChange);
  useHistoryUpdater(history, filters, isBrowserNavigating, setIsBrowserNavigating);

  const searchQuery = () => {
    return buildSearchQuery(filters);
  };

  return (
    <SearchFiltersContext.Provider value={searchFiltersContext}>
      {children({
        pathname: history.location.pathname,
        searchQuery: searchQuery(),
      })}
    </SearchFiltersContext.Provider>
  );
}

export default withTravelPreferences(SearchFiltersProvider, { includeSetter: true });
