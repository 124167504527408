import React from 'react';
import styled from 'styled-components';

import { Box } from 'core/ui';

import { Headline, Footer, FooterLink } from './RegionalNearbyDumpstations.styles';

const Hero = styled(Box)`
  width: 100%;
  padding-bottom: 37%;
  background: ${({ theme }) => theme.colors.gray200} url('${({ image }) =>
  image}') no-repeat center center;
  background-size: cover;
  margin-bottom: 1rem;
`;

const SimpleDumpstationGrid = ({ location, dumpstation, stateParam }) => {
  return (
    <>
      <Headline>RV Dump Stations Near  {location}</Headline>
      {!!dumpstation.image && <Hero image={dumpstation.image} />}
      <div dangerouslySetInnerHTML={{ __html: dumpstation.content }} />
      <Footer>
        <FooterLink
          href={`/dumpstations/${stateParam}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View all dump stations near {location}
        </FooterLink>
      </Footer>
    </>
  );
};

export default SimpleDumpstationGrid;