import React from 'react';
import styled from 'styled-components';

import { Button as BaseButton, Text } from 'core/ui';

const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.coreBlue};

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.coreBlue};
  }
  svg {
    position: relative;
    top: 1px;
  }
`;

function MobileMapToggle({ onSearchMapToggle, searchMapVisible }) {
  let iconAndText = (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 576 512"
      >
        <path
          d="M408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 70 120 200 120 200s120-130 120-200zm-160-8a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM373.9 265.1C366.4 275.3 359 285 352 293.9V452.2L224 405.7V293.9c-7-8.8-14.4-18.5-21.9-28.8c-8.8-12.1-17.8-25.1-26.1-38.6V405.3l-128 41V195l96.6-30.9c-5.2-14.6-8.6-29.6-8.6-44.1c0-1.2 0-2.4 0-3.5L0 160V461.6 512l48-15.4L200 448l176 64 200-64V146.4 96l-48 15.4-90 28.8c-3.6 19-11.5 38.1-20.3 55.2l-1.1 2L528 161.8V413l-128 41V226.5c-8.4 13.4-17.3 26.5-26.1 38.6z" 
          fill="#004665"
        />
      </svg>
      <Text ml="5px" fontSize="15px" as="span">
        Map
      </Text>
    </>
  );

  if (searchMapVisible) {
    // show list
    iconAndText = (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14.75"
          height="12.357"
          viewBox="0 0 512 512"
        >
          <path
            d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"
            fill="#004665"
          />
        </svg>
        <Text ml="5px" fontSize="15px" as="span">
          List
        </Text>
      </>
    );
  }

  return (
    <Button type="button" variant="linkPrimary" fontSize="14px" onClick={onSearchMapToggle}>
      {iconAndText}
    </Button>
  );
}

export default React.memo(MobileMapToggle);
