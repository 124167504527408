import React from 'react';
import get from 'just-safe-get';

import ResponsiveModal from 'components/ResponsiveModal';
import SearchFiltersForm from './SearchFiltersForm';

import useSearchFilters from '../../hooks/useSearchFilters';
import useSearchExplorer from '../../hooks/useSearchExplorer';

function SearchFiltersModal(props) {
  const {
    filters,
    isOpen,
    hideModal,
    onSubmit,
    formik,
    totalResults,
    ...restProps
  } = props;
  const searchExplorerContext = useSearchExplorer();
  const { changeSearchFilters, ...otherSearchFilterProps } = useSearchFilters();
  const pagination = get(searchExplorerContext, 'pagination') || {};

  return (
    <ResponsiveModal
      isOpen={isOpen}
      data={{ id: 'more-filters-modal' }}
      style={{ content: {
        borderRadius: '16px',
        height: '100%',
        overflowY: 'scroll',
        padding: '0 0 200px 0'
      } }}
    >
      <SearchFiltersForm
        filters={filters}
        hideModal={hideModal}
        pagination={pagination}
        onChange={changeSearchFilters}
        onSubmit={(...args) => {
          onSubmit(...args);
          hideModal();
        }}
        {...otherSearchFilterProps}
        {...restProps}
      />
    </ResponsiveModal>
  );
}

export default React.memo(SearchFiltersModal);
