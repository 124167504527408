import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { Field } from 'formik';

import useAxios from 'core/hooks/useAxios';
import useDebounce from 'core/hooks/useDebounce';
import SafeTracking from 'tracking/SafeTracking';

import MagnifyingGlass from 'images/icons/MagnifyingGlass';
import { AutocompleteForm } from './styles';

function AutoCompleteComponent({ field, form, closeDropdown, hasIcon, placeholder }) {
  const [searchTerm, setSearchTerm] = useState(field.value);
  const keywords = useDebounce(searchTerm, 500);
  const [suggestions, setSuggestions] = useState([]);
  const [hasSearched, setHasSearched] = useState(searchTerm.length > 0);
  const [shouldSuggest, setShouldSuggest] = useState(true);

  const getTrigger = () => {
    if (keywords.length > 2 && shouldSuggest) {
      return keywords;
    }
    return undefined;
  };

  const { response } = useAxios({
    url: 'v1/rvs/autocomplete',
    options: {
      params: {
        keywords,
        lat: form.initialValues.destination?.lat,
        lng: form.initialValues.destination?.lng,
      },
    },
    trigger: getTrigger(),
  });

  useEffect(() => {
    if (response && response.data.suggestions && keywords.length > 2) {
      setSuggestions(response.data.suggestions);
    } else setSuggestions([]);
  }, [response, keywords]);

  const resetSearch = () => {
    setSearchTerm('');
    setSuggestions([]);
    if (field.value.length === 0) {
      form.setFieldValue(field.name, '');
    } else {
      form.setFieldValue(field.name, searchTerm);
    }
    setHasSearched(false);
  };

  useEffect(() => {
    if ((keywords.length === 0 || field.value.length === 0) && hasSearched) {
      resetSearch();
    }
    // eslint-disable-next-line
  }, [keywords, hasSearched, field.value]);

  const getSuggestionValue = suggestion => {
    setShouldSuggest(false);
    return suggestion;
  };

  const renderSuggestion = suggestion => {
    return <div>{suggestion}</div>;
  };

  const onSearchSubmit = () => {
    form.setFieldValue(field.name, searchTerm);
    setHasSearched(true);
    SafeTracking.track('Keyword search', { search_term: searchTerm });

    if (typeof closeDropdown === 'function') {
      closeDropdown();
    }
  };

  const customInputProps = {
    name: 'keywords',
    value: searchTerm,
    onBlur: event => {
      field.onBlur(event);
      onSearchSubmit();
    },
    onChange: (e, { newValue }) => {
      setSearchTerm(newValue);
    },
    onKeyPress: () => setShouldSuggest(true),
    ...(placeholder && { placeholder }),
  };
  return (
    <>
      <AutocompleteForm>
        {hasIcon && <MagnifyingGlass className="search-icon" />}
        <Autosuggest
          inputProps={customInputProps}
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          // These are required props, but we don't need them to do anything
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          renderSuggestionsContainer={({ containerProps, children }) => {
            return (
              <div
                {...containerProps}
                style={{ height: '150px', overflowY: 'auto', border: '1px solid #ccc' }}
              >
                {children}
              </div>
            );
          }}
        />
      </AutocompleteForm>
    </>
  );
}

export default function AutoCompleteField({ name, closeDropdown, hasIcon, placeholder, value }) {
  return (
    <Field
      name={name}
      component={AutoCompleteComponent}
      closeDropdown={closeDropdown}
      hasIcon={hasIcon}
      placeholder={placeholder}
      value={value}
    />
  );
}
