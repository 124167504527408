import React from 'react';
import get from 'just-safe-get';
import styled from 'styled-components';

import { Flex } from 'core/ui';

import useSearchFilters from '../../hooks/useSearchFilters';
import useSearchExplorer from '../../hooks/useSearchExplorer';
import SearchFiltersForm from './SearchFiltersForm';

const Container = styled.div`
  padding: 0 16px;

  ${({ theme }) => theme.medium`
    padding: 10px 20px 0 20px;
  `}

  button {
    border-radius: 30px;
  }
`;

function DesktopSearchFilters(props) {
  const searchExplorerContext = useSearchExplorer();
  const pagination = get(searchExplorerContext, 'pagination') || {};
  const nightlyRateStats = props.nightlyRateStats || {};
  const histograms = props.histograms || {};
  const { changeSearchFilters, ...otherSearchFilterProps } = useSearchFilters();

  return (
    <Container>
      <Flex justifyContent="space-between">
        <SearchFiltersForm
          currentUser={props.currentUser}
          onChange={changeSearchFilters}
          pagination={pagination}
          nightlyRateStats={nightlyRateStats}
          histograms={histograms}
          {...otherSearchFilterProps}
        />
      </Flex>
    </Container>
  );
}

export default React.memo(DesktopSearchFilters);
