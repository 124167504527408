import { useState, useReducer, useEffect, useMemo, useCallback } from 'react';

import useAxios from 'core/hooks/useAxios';
// import usePrevious from 'core/hooks/usePrevious';

import { parseServerResponse } from '../helpers/Results';

const defaultState = {
  variant: 'unknown',
  loading: false,
  errors: null,
  results: [],
  pagination: null,
  nightlyRateStats: null,
  nightlyRateHistogram: null,
  scores: null,
};

const FETCH_RESULTS = 'FETCH_RESULTS';
const RESULTS_SUCCESS = 'RESULTS_SUCCESS';
const RESULTS_FAILED = 'RESULTS_FAILED';

const reducer = (state, action = {}) => {
  switch (action.type) {
    case FETCH_RESULTS:
      return {
        ...state,
        loading: true,
      };

    case RESULTS_SUCCESS:
      return {
        ...state,
        ...parseServerResponse(action.payload),
        loading: false,
      };

    case RESULTS_FAILED:
      return {
        ...defaultState,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

const fetchSearchResults = payload => ({ type: FETCH_RESULTS, payload });
const resultsSuccess = payload => ({ type: RESULTS_SUCCESS, payload });
const resultsFailed = payload => ({ type: RESULTS_FAILED, payload });

export default function useSearchApi(initialState, pathname) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoInitialState = useMemo(() => parseServerResponse(initialState), []);
  const [state, dispatch] = useReducer(reducer, memoInitialState);

  // start with query as undefined because we get initial results from the server
  const [query, setQuery] = useState(undefined);
  // const prevQuery = usePrevious(query);

  const fetchResults = useCallback((newQuery) => {
    // Changing the filters will generate a trigger
    // and fetch new results
    setQuery(newQuery);
  }, []);

  const { response, loading, error } = useAxios({
    url: `${pathname}.json?${query}`,
    options: {
      baseURL: '/',
    },
    // trigger = undefined ? use axios does nothing
    // trigger = string, axios makes the call until it gets a new string
    trigger: query,
  });

  // Set the values in the search reducer based on the status
  // of the call to the search API
  useEffect(() => {
    if (loading) {
      dispatch(fetchSearchResults());
    } else if (response) {
      dispatch(resultsSuccess(response.data.data));
    } else if (error) {
      dispatch(resultsFailed({ error: [error.message] }));
    }
  }, [response, loading, error]);

  return {
    state,
    fetchResults,
  };
}
