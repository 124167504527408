import React from 'react';
import get from 'just-safe-get';
import styled from 'styled-components';

import { withSearchHistograms } from 'core/state/Globals';
import SliderWithInputsBargraph from 'components/SliderWithInputsBargraph';

import { HeadlineText, Indent } from './filterStyles';

const MIN_KEY = 'minWater';
const MAX_KEY = 'maxWater';

const Wrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

function FreshWaterTankFilter({
  filters,
  formik,
  handleSaveValues,
  includeHeader = true,
  indent = true,
  searchHistograms,
}) {
  const { minWater, maxWater } = filters;
  const { buckets } = searchHistograms.freshWaterTankHistogram;
  const freshWaterTankMin = buckets?.[0] ? buckets[0]?.key : 0;
  const freshWaterTankMax = buckets?.[0] ? buckets[buckets.length - 1]?.key : 100; // If no results, just set to 100

  const min = get(formik, ['values', MIN_KEY]) || minWater;
  const max = get(formik, ['values', MAX_KEY]) || maxWater;

  const data = buckets.map(bucket => {
    return {
      distance: bucket.doc_count,
      colors: ['#91918e', '#91918e'],
      label: bucket.key,
      start: bucket.key,
      end: bucket.key,
    };
  });

  const handleSave = vals => {
    handleSaveValues({
      [MIN_KEY]: vals[0],
      [MAX_KEY]: vals[1],
    });
  };

  const sliderProps = {
    fieldKeyMin: MIN_KEY,
    fieldKeyMax: MAX_KEY,
    defaultMin: freshWaterTankMin,
    defaultMax: freshWaterTankMax,
    step: 1,
    sliderLabel: 'gallons',
    handleSaveValues: handleSave,
    min,
    max,
    isMoney: false,
    barData: data,
    srTextMax: 'adjust maximum fresh water tank in gallons',
    srTextMin: 'adjust minimum frest water tank in gallons',
  };

  return (
    <Wrapper>
      {includeHeader && (
        <HeadlineText className="filterHeadline">Fresh Water Tank (in gallons)</HeadlineText>
      )}
      {indent ? (
        <Indent top>
          <SliderWithInputsBargraph {...sliderProps} />
        </Indent>
      ) : (
        <SliderWithInputsBargraph {...sliderProps} />
      )}
    </Wrapper>
  );
}

export default React.memo(withSearchHistograms(FreshWaterTankFilter));
