import { useState, useCallback, useEffect, useRef } from 'react';
import SafeTracking from 'tracking/SafeTracking';

export default function useSearchMap(isDetectedMobile) {
  // Default is true for desktop
  const [mapVisible, setMapVisible] = useState(false);

  const [mapLoadingVisible, setMapLoadingVisible] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);

  const handleMapLoadingToggle = useCallback(
    (isVisible) => {
      if (!mapLoaded) {
        // only set if false, to avoid re-render
        setMapLoaded(true);
      }

      if (!isDetectedMobile) {
        setMapLoadingVisible(isVisible);
      }
    },
    [isDetectedMobile, mapLoaded],
  );

  const handleSearchMapToggle = useCallback(() => {
    handleMapLoadingToggle(true);

    setMapVisible(prevState => !prevState);

    SafeTracking.track('Search Map Toggle Click');
  }, [handleMapLoadingToggle]);

  const isFirstLoadRef = useRef(true);
  useEffect(() => {
    if (isFirstLoadRef.current) {
      // this prevent Google Maps from loading on mobile (when hidden by default)
      isFirstLoadRef.current = false;
      return;
    }
    // dispatch resize so the carousel knows to recalculate
    window.dispatchEvent(new Event('resize'));

    setTimeout(() => handleMapLoadingToggle(false), 400);

    if (window.innerWidth < 768) {
      const searchResultsElement = document.getElementById('search_results');
      const wrapperOffsetTop = searchResultsElement.offsetTop;
      if (window.scrollY > wrapperOffsetTop) {
        window.scroll(0, wrapperOffsetTop - 61);
      }
    }

    // eslint-disable-next-line
  }, [mapVisible]);

  useEffect(() => {
    // on initial load, if desktop looking device load the map into the DOM
    if (!isDetectedMobile) {
      setMapLoaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return {
    mapVisible,
    mapLoadingVisible,
    mapLoaded,
    handleSearchMapToggle,
  };
}
