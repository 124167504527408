import React from 'react';
import get from 'just-safe-get';
import styled from 'styled-components';
import Dropdown from 'components/DropdownCustom';
import { useFocus } from 'search/helpers/FocusContext';
import { Button, Text } from './styles';

const NoticeContainer = styled.div`
  font-size: 0.875rem;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  svg {
    margin-right: 0.5rem;
    color: ${props => props.theme.colors.primaryBlue};
    margin: 0.85em 0.75em;
    margin-left: 0;
  }
`;

const NoticeText = styled.p`
  font-size: 0.9rem;
`;

const EditDeliveryButton = styled(Button)`
  background-color: ${props => props.theme.colors.grey100};
  outline: none;
  border: none;
  color: ${props => props.theme.colors.primaryBlue};
  font-weight: 300;
`;

const CloseButton = styled.button`
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  transition: opacity 100ms ease-in-out;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 1rem 1rem;
  margin: -1rem -0.5rem -1rem auto;

  &:hover {
    opacity: 1;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
  background-color: ${props => props.theme.colors.grey100};
`;

const LocationText = styled.p`
  margin: 0;
  flex: 1;
  color: ${props => props.theme.colors.primaryBlue};
`;

function DeliveryNotice({ closeNotice, filters }) {
  const { focusInput } = useFocus();

  const handleButtonClick = () => {
    focusInput();
    closeNotice();
  };

  const { location } = filters.destination;

  return (
    <NoticeContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NoticeText>The owner will deliver the RV to your location:</NoticeText>
        <CloseButton type="button" onClick={closeNotice} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </CloseButton>
      </div>
      <LocationContainer>
        <LocationText>{location}</LocationText>
        <EditDeliveryButton type="button" onClick={handleButtonClick} aria-label="Focus Search Input">
          Edit
        </EditDeliveryButton>
      </LocationContainer>
    </NoticeContainer>
  );
}

function DeliveryButton(props) {
  const { setFieldValue, isFilterSelected } = props;
  const selected = isFilterSelected(['delivery']);

  const toggleDelivery = () => {
    setFieldValue('delivery', !selected);
  };

  const button = ({ handleToggle, open }) => {
    const handleClick = () => {
      if (!selected) {
        handleToggle();
        toggleDelivery();
        return;
      }

      if (open) {
        handleToggle();
      }

      toggleDelivery();
    };

    return (
      <Button
        type="button"
        selected={selected}
        aria-controls="pets-filter"
        aria-haspopup="true"
        aria-expanded={!!selected}
        onClick={handleClick}
      >
        <Text ml="8px" mr="10px" fontSize="14px">
          Delivery
        </Text>
      </Button>
    );
  };

  return (
    <Dropdown
      button={button}
      id="desktop-delivery-button"
      dropdownStyles={{ padding: '10px 20px' }}
    >
      {({ handleToggle }) => <DeliveryNotice closeNotice={handleToggle} filters={props.filters} />}
    </Dropdown>
  );
}

function arePropsEqual(prevProps, nextProps) {
  const pathToObj = 'filters.delivery';
  return get(prevProps, pathToObj) === get(nextProps, pathToObj);
}

export default React.memo(DeliveryButton, arePropsEqual);
