import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  path {
    fill: #aaa;
  }
`;

export default function MagniyingGlass({ className }) {
  return (
    <Icon className={className} xmlns="http://www.w3.org/2000/svg" width="19.879" height="19.863" viewBox="0 0 19.879 19.863">
      <g>
        <path d="M22.083-79.321L17.4-84.006a8.348 8.348 0 0 0-.648-11.046 8.335 8.335 0 0 0-11.8 0 8.335 8.335 0 0 0 0 11.8A8.348 8.348 0 0 0 16-82.6l4.686 4.686a1.01 1.01 0 0 0 1.4 0 .972.972 0 0 0 0-1.4zM6.35-84.655a6.352 6.352 0 0 1 0-9 6.352 6.352 0 0 1 9 0 6.352 6.352 0 0 1 0 9 6.35 6.35 0 0 1-9 0z" transform="translate(-2.502 97.5)" />
      </g>
    </Icon>
  );
}
