import React from 'react';
import styled, { css } from 'styled-components';

import { Link as BaseLink } from 'core/ui';

const textStyles = css`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 15px;
  font-weight: normal;
`;

const Wrapper = styled.div`
  padding-top: 10px;

  ${props => props.theme.medium`
    padding-top: 20px;
  `}
`;

const OrderedList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li``;

const Link = styled(BaseLink)`
  ${textStyles}
`;

const LastItem = styled.span`
  ${textStyles}
`;

const GreaterThanSign = styled.span`
  ${textStyles}
  margin-right: 9px;
  margin-left: 9px;
`;

function SearchBreadcrumbs(props) {
  const { homePath, searchPath, state, stateLanderPath, regionName } = props;

  return (
    <Wrapper>
      <nav aria-label="breadcrumb">
        <OrderedList>
          <ListItem>
            <Link href={homePath} data-event-category="Search" data-event-action="RV Rentals" data-event-label="Breadcrumbs">
              <span>RV Rentals</span>
            </Link>

            <GreaterThanSign>&gt;</GreaterThanSign>
          </ListItem>

          <ListItem>
            <Link href={searchPath} data-event-category="Search" data-event-action="United States" data-event-label="Breadcrumbs">
              <span>United States</span>
            </Link>
          </ListItem>

          {!!state && (
            <>
              <GreaterThanSign>&gt;</GreaterThanSign>
              <ListItem>
                <Link href={stateLanderPath} data-event-category="Search" data-event-action={`State: ${state}`} data-event-label="Breadcrumbs">
                  <span>{state}</span>
                </Link>
              </ListItem>
            </>
          )}

          <ListItem aria-current="page">
            <GreaterThanSign>&gt;</GreaterThanSign>

            <LastItem>{regionName || 'Search Results'}</LastItem>
          </ListItem>
        </OrderedList>
      </nav>
    </Wrapper>
  );
}

export default React.memo(SearchBreadcrumbs);
