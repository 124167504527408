import React from 'react';

import CombinedSearchExplorer from './CombinedSearchExplorer';

function RegionalSearchExperiment(props) {
  const {
    optimizelyData,
    history,
    searchResults,
    regionList,
    titleText,
    adData,
    optControllinkPaths,
  } = props;

  return (
    <CombinedSearchExplorer
      history={history}
      searchResults={searchResults}
      titleText={titleText}
      regionList={regionList}
      adData={adData}
      optimizelyData={optimizelyData}
      isRegionalLander
      breadcrumbs={optControllinkPaths}
    />
  );
}

export default RegionalSearchExperiment;
