/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { connect as connectFormik } from 'formik';
import range from 'array-range';

import { Flex } from 'core/ui';
import { DownshiftAutocomplete } from 'components/DownshiftAutocomplete';
import ResponsiveRender from 'components/ResponsiveRender';
import ModelYearDisclaimer from 'search/components/ModelYearDisclaimer';
import useMakes from 'search/hooks/useMakes';
import useModels from 'search/hooks/useModels';
import useDataset from 'search/hooks/useDataset';

import ClassTypeIcon from '../ClassTypes/ClassTypeIcon';

import ApplyButtons, {
  Title,
  ButtonClassType,
  Description,
  Label,
  Ul,
  Li,
  HiddenCheckbox,
  VehicleMatchHeader,
  AutocompleteWrapper,
  VehicleMatchFooter,
} from './styles';

function VehicleTowingMatch({
  options,
  closeDropdown,
  formik: { values, setValues, setFieldValue },
}) {
  const truckDataYears = range(2005, 2024) // range is exclusive of second value
    .map(year => ({
      value: year,
      label: year,
    }))
    .reverse();

  const { makes, makesLoading } = useMakes(values?.towingVehicle?.year);
  const { models, modelsLoading } = useModels(
    values?.towingVehicle?.year,
    values?.towingVehicle?.make
  );

  const vehicleMatchOnChange = field => {
    const value = (field.value || '').toString();

    // if the year changes, clear out previous make/model/weight
    if (field.name === 'towingVehicle.year') {
      setValues({
        ...values,
        towingVehicle: {
          year: value,
          make: '',
          model: '',
          towingWeight: '',
        },
      });

      return;
    }

    // if the make changes, clear out previous model/weight
    if (field.name === 'towingVehicle.make') {
      setValues({
        ...values,
        towingVehicle: {
          ...values?.towingVehicle,
          make: value,
          model: '',
          towingWeight: '',
        },
      });
      return;
    }
    setFieldValue(field.name, value);
  };

  // grab interact rv data from custom hook
  const { data: vehicleTowingMatchData, dataLoading, hasNoMatch } = useDataset(
    values.towingVehicle?.year,
    values.towingVehicle?.make,
    values.towingVehicle?.model
  );

  // if dataset is available create custom vehicle object || undefined
  const updatedTowingVehicle = vehicleTowingMatchData
    ? {
        year: vehicleTowingMatchData.year.toString(),
        make: vehicleTowingMatchData.make,
        model: vehicleTowingMatchData.model,
        towingWeight: vehicleTowingMatchData.max_towing_capacity.toString(),
      }
    : undefined;

  // only trigger if there was a match and we have a completed towing vehicle
  const shouldUpdateTowingVehicle = JSON.stringify(updatedTowingVehicle) && !hasNoMatch;
  React.useEffect(() => {
    if (shouldUpdateTowingVehicle) {
      const rvClasses = values.rvClass || {};

      // only toggle towable classes if user hasn't done that on his own
      // we dont want to override their selected towable types
      const shouldEnableTowableTypes = Object.keys(rvClasses).every(key => !rvClasses[key]);

      // filter for all towable classes
      const enableTowableTypes = options.reduce((accum, option) => {
        accum[`${option.searchValue || option.value}`] = true;
        return accum;
      }, {});

      // update towing vehicle values with our dataset values
      setValues({
        ...values,
        ...(shouldEnableTowableTypes && {
          rvClass: {
            ...rvClasses,
            ...enableTowableTypes,
          },
        }),
        towingVehicle: updatedTowingVehicle,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateTowingVehicle]);

  const handleClear = useCallback(() => {
    // clear all towable filters and towing vehicle
    const currentValues = options.reduce((accum, option) => {
      accum[`${option.searchValue || option.value}`] = false;
      return accum;
    }, {});

    setValues({
      ...values,
      rvClass: {
        ...currentValues,
      },
      towingVehicle: {
        year: '',
        make: '',
        model: '',
        towingWeight: '',
      },
    });
  }, [options, setValues, values]);

  const disableMakeModel = !values?.towingVehicle?.year;
  const disableModel =
    disableMakeModel || (values?.towingVehicle?.year && !values?.towingVehicle?.make);
  const disableSubmit =
    makesLoading ||
    modelsLoading ||
    dataLoading ||
    (values?.towingVehicle?.year && !values?.towingVehicle?.make) ||
    (values?.towingVehicle?.year && values?.towingVehicle?.make && !values?.towingVehicle?.model);

  return (
    <ResponsiveRender>
      {isMobile => (
        <>
          <Title>{isMobile ? 'Towing Vehicle' : 'Choose Towable RV Type'}</Title>

          {!isMobile && (
            <>
              <Ul>
                {options.map(option => {
                  const name = `rvClass.${option.searchValue || option.value}`;

                  return (
                    <Li key={option.id}>
                      <>
                        <Label htmlFor={option.id}>
                          <HiddenCheckbox name={name} id={option.id} />

                          <ButtonClassType type="button" variant="blueBorder">
                            <ClassTypeIcon type={option.id.toUpperCase()} />
                            <Description>
                              <p>{option.label}</p>
                              {option.description}
                            </Description>
                          </ButtonClassType>
                        </Label>
                      </>
                    </Li>
                  );
                })}
              </Ul>
            </>
          )}
          <Flex flexDirection="column" width="75%" mb={!isMobile ? '.75rem' : '2.5rem'}>
            {!isMobile && (
              <VehicleMatchHeader>
                Enter your vehicle information to see RVs that your vehicle can tow
              </VehicleMatchHeader>
            )}
            <AutocompleteWrapper>
              <DownshiftAutocomplete
                selectedValue={values?.towingVehicle?.year || ''}
                handleChange={vehicleMatchOnChange}
                items={truckDataYears}
                placeholder="Year"
                name="towingVehicle.year"
                id="towing_vehicle_year"
                data-testid="towing_vehicle_year"
                inputHeight="36px"
              />
              <DownshiftAutocomplete
                selectedValue={values?.towingVehicle?.make || ''}
                handleChange={vehicleMatchOnChange}
                items={makes}
                loading={makesLoading}
                placeholder="Make"
                name="towingVehicle.make"
                disabled={disableMakeModel}
                hideClearSelection
                id="towing_vehicle_make"
                data-testid="towing_vehicle_make"
                inputHeight="36px"
              />
              <DownshiftAutocomplete
                selectedValue={values?.towingVehicle?.model || ''}
                handleChange={vehicleMatchOnChange}
                items={models}
                loading={modelsLoading}
                placeholder="Model"
                name="towingVehicle.model"
                disabled={disableModel}
                hideClearSelection
                id="towing_vehicle_model"
                data-testid="towing_vehicle_model"
                containerStyles={!isMobile && { gridColumnEnd: 'span 2' }}
                inputHeight="36px"
              />
            </AutocompleteWrapper>
            <ModelYearDisclaimer year={values?.towingVehicle?.year} />
          </Flex>
          {!isMobile && (
            <VehicleMatchFooter>
              <ApplyButtons
                handleClear={handleClear}
                closeDropdown={closeDropdown}
                disabled={disableSubmit}
              />
              <p id="vehicle_match_disclaimer">
                Results are displayed based on user input, owner-supplied content, third-party
                manufacturer data, and estimated gross weights. Renters should perform their own
                research before selecting an RV to rent including determining whether their towing
                vehicle is compatible with an RV. RVshare is not responsible and shall not be held
                liable for any listed RVs that do not match a user&apos;s preferred criteria.
              </p>
            </VehicleMatchFooter>
          )}
        </>
      )}
    </ResponsiveRender>
  );
}

export default connectFormik(React.memo(VehicleTowingMatch));
