import React from 'react';
import styled from 'styled-components';

import ContentSection, { SectionWrapper } from 'content/Content';
import {DumpstationCardContainer} from 'content/buildContentCarouselCards';

import { Headline, Footer, FooterLink } from './RegionalNearbyDumpstations.styles';

const SimpleDumpstationContainer = styled.main`
  ${DumpstationCardContainer}{
    margin: 0.5rem 0 0 0;
  }

  ${SectionWrapper}{
    padding: 0;
  }

  .flickity-viewport{
    margin-bottom: 1rem;
  }
`;

const DumpstationGrid = ({ location, dumpstation, dumpstations, stateParam }) => {
  return (
    <SimpleDumpstationContainer className="parks-main">
      <Headline>RV Dump Stations Near {location}</Headline>
      <div dangerouslySetInnerHTML={{ __html: dumpstation.content }} />
      <ContentSection
        id='dumpstations'
        background='#fff'
        carouselVariant='dumpstation'
        carouselItems={dumpstations}
      />
      <Footer>
        <FooterLink
          href={`/dumpstations/${stateParam}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View all dump stations near {location}
        </FooterLink>
      </Footer>
    </SimpleDumpstationContainer>
  );
};

export default DumpstationGrid;