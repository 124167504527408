import React from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

import { SliderRail, KeyboardHandle, Track } from './SliderComponents';

const sliderStyle = {
  position: 'relative',
  width: '100%',
  height: '1px',
};

function RangeSlider(props) {
  const {
    values,
    onUpdate,
    onChange,
    allowedMin,
    allowedMax,
    mode = 1,
    step = 10,
    bargraph,
    srTextMin = 'adjust minimum slider value',
    srTextMax = 'adjust maximum slider value',
  } = props;
  const domain = [allowedMin, allowedMax];

  return (
    <Slider
      mode={mode}
      step={step}
      domain={domain}
      rootStyle={sliderStyle}
      onUpdate={onUpdate}
      onChange={onChange}
      values={values}
    >
      <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} bargraph={bargraph} />}</Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle, i) => (
              <KeyboardHandle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
                bargraph={bargraph}
                srText={i === 0 ? srTextMin : srTextMax}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} bargraph={bargraph} />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );
}

export default React.memo(RangeSlider);
