import styled from 'styled-components';

export const Headline = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

export const Footer = styled.div`
  margin: 2rem 0;
  font-size: 1.3rem;
  font-weight: bold;

  ${({ theme }) => theme.medium`
    text-align: right;
    margin: 0 0 2rem 0;
  `};
`;

export const FooterLink = styled.a`
  display: inline-block;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.bodyColor};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.linkColor};
  }
`;