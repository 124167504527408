/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { connect as connectFormik } from 'formik';

import useSearchFilters from 'search/hooks/useSearchFilters';
import useWindowSize from 'core/hooks/useWindowSize';

import Modal, { ModalHeader, CloseButtonStyled } from 'components/Modal';
import ToggleContent from 'components/ToggleContent';

import SafeTracking from 'tracking/SafeTracking';
import AmenitiesFilter from '../AmenitiesFilter';
import ElectricServiceFilter from '../ElectricServiceFilter';
import FreshWaterTankFilter from '../FreshWaterTankFilter';
import GeneratorFilter from '../GeneratorFilter';
import KeywordFilter from '../KeywordFilter';
import LengthFilter from '../LengthFilter';
import MileageFilter from '../MileageFilter';
import MiscFilter from '../MiscFilter';
import PriceFilter from '../PriceFilter/StandAlonePriceFilter';
import RentalOptionsFilter from '../RentalOptionsFilter';
import RulesFilter from '../RulesFilter';
import RvTypeFilter from '../RvTypeFilter';

import ApplyButtons from '../ApplyButtons';

import {
  BottomRow,
  Button,
  ButtonIcon,
  Col,
  DropDownRow,
  HeadlineText,
  ModalContent,
  ModalHeading,
  Text
} from '../filterStyles'

function AllFiltersDropdown(props) {
  const {
    filters,
    isFilterSelected,
    title,
    handleFormikSaveValues,
    formik,
    totalResults,
    drivableOptions,
    towableOptions,
  } = props;
  const [selectedCountText, setSelectedCountText] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  const { height } = useWindowSize();
  const contentHeight = height * .7;
  const applyButtonText = totalResults ? `Show ${totalResults} RVs` : 'Apply changes';

  const { clearAllFilters } = useSearchFilters();

  const handleClear = () => {
    clearAllFilters(['sleeps']);
  };

  const filtersSelectedList = [
    'amenities',
    'cancellationPolicy',
    'electricService',
    'electricService.30',
    'electricService.50',
    'delivery',
    'instantBook',
    'isStationary',
    'keywords',
    'min',
    'max',
    'maxGenHours',
    'minGenHours',
    'maxLength',
    'minLength',
    'maxMiles',
    'minMiles',
    'minWater',
    'maxWater',
    'pets',
    'rvClass',
    'smoking',
  ];

  useEffect(() => {
    const selectedCount = isFilterSelected(filtersSelectedList, true);
    // use temp var for State timing issues.
    const tmpIsSelected = !!(selectedCount && selectedCount > 0);
    setIsSelected(tmpIsSelected);
    setSelectedCountText(tmpIsSelected ? ` (${selectedCount})` : '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOnClick = (show) => {
    SafeTracking.track('Filters CTA Click',
      { rvsIntegrations: { all: false, segment: true } });
      show();
  };

  return (
    <ToggleContent
      toggle={show => (
        <Button
          type="button"
          onClick={() => handleOnClick(show)}
          selected={isSelected}
          data-event-category="Search Filter Nav"
          data-event-action={`${title} Icon`}
          data-event-label={title}
        >
          <Text ml="8px" mr="10px" fontSize="14px">
            {title}{selectedCountText}
            <ButtonIcon className="ArrowIcon" icon='angle-down' />
          </Text>
        </Button>
      )}
      content={hide => (
        <Modal
          isOpen
          onRequestClose={hide}
          data={{ id: 'more-filters-modal' }}
          style={{ overlay: { overflowY: 'scroll' }, content: { borderRadius: '16px', maxWidth: '780px' } }}
        >
          <ModalHeader alignItems="flex-start" justifyContent="space-between">
            <ModalHeading>{title}</ModalHeading>

            <CloseButtonStyled type="button" onClick={hide} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </CloseButtonStyled>
          </ModalHeader>
          <ModalContent contentHeight={contentHeight}>
            <Col>
              <HeadlineText>Rental Options</HeadlineText>
              <RentalOptionsFilter
                handleFormikSaveValues={handleFormikSaveValues}
                filters={filters}
              />
            </Col>
            <DropDownRow>
              <PriceFilter
                handleSaveValues={handleFormikSaveValues}
                formik={formik}
                filters={filters}
              />
            </DropDownRow>
            <Col>
              <HeadlineText>RV Type</HeadlineText>
              <RvTypeFilter
                drivableOptions={drivableOptions}
                towableOptions={towableOptions}
              />
            </Col>
            <Col>
              <HeadlineText>Power and Water</HeadlineText>
              <ElectricServiceFilter
                filters={filters}
                formik={formik}
                handleFormikSaveValues={handleFormikSaveValues}
              />
              <MiscFilter
                amenity="Electric Generator"
                modifier="an"
                filters={filters}
                handleFormikSaveValues={handleFormikSaveValues}
              />
              <MiscFilter
                amenity={`Hot & Cold Water Supply`}
                filters={filters}
                handleFormikSaveValues={handleFormikSaveValues}
              />
            </Col>
            <DropDownRow>
              <MileageFilter
                handleSaveValues={handleFormikSaveValues}
                formik={formik}
                filters={filters}
              />
            </DropDownRow>
            <DropDownRow>
              <GeneratorFilter
                filters={filters}
                formik={formik}
                handleSaveValues={handleFormikSaveValues}
              />
            </DropDownRow>
            <Col>
              <HeadlineText>Rental Rules</HeadlineText>
              <RulesFilter
                handleFormikSaveValues={handleFormikSaveValues}
              />
            </Col>
            <Col>
              <HeadlineText>Amenities</HeadlineText>
              <AmenitiesFilter
                handleFormikSaveValues={handleFormikSaveValues}
              />
            </Col>
            <DropDownRow>
              <LengthFilter
                filters={filters}
                formik={formik}
                handleSaveValues={handleFormikSaveValues}
              />
            </DropDownRow>
            <DropDownRow>
              <FreshWaterTankFilter
                filters={filters}
                handleSaveValues={handleFormikSaveValues}
              />
            </DropDownRow>
            <Col style={{ paddingBottom: '150px' }}>
              <HeadlineText>Keyword Search</HeadlineText>
              <KeywordFilter filters={filters} />
            </Col>
          </ModalContent>
          <BottomRow>
            <ApplyButtons
              applyText={applyButtonText}
              applyVariant='blue'
              clearText='Clear all'
              closeDropdown={hide}
              handleClear={handleClear}
            />
          </BottomRow>
        </Modal>
      )}
    />
  );
}

export default connectFormik(React.memo(AllFiltersDropdown));
