import React from 'react';
import { connect as formikConnect } from 'formik';

import { KeywordWrapper } from './filterStyles';
import AutoCompleteField from './KeyWord/AutoCompleteField';

function KeywordFilter({ filters }) {
  const { keywords } = filters;
  return (
    <KeywordWrapper>
      <AutoCompleteField hasIcon name="keywords" placeholder="Enter make, model, keyword" value={keywords} />
    </KeywordWrapper>
  );
}

export default React.memo(formikConnect(KeywordFilter));
