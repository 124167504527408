import React, { useEffect } from 'react';
import canUseDOM from 'can-use-dom';
import loadable from '@loadable/component';

import { Flex, Box } from 'core/ui';

import { withDeviceDetection } from 'core/state/Globals';
import scrollToTop from 'core/helpers/scrollToTop';

import Alert from 'components/Alert';
import ResponsiveRender from 'components/ResponsiveRender';
import Breadcrumbs from 'components/Breadcrumbs';

import useSearchMap from '../hooks/useSearchMapFullScreen';
import { ResultHoverProvider } from '../hooks/useResultHover';
import SearchFilters from './SearchFilters/SearchFilters';

import SearchResultsHeader from './SearchResults/SearchResultsHeader';
import SearchResultsGrid from './SearchResults/SearchResultsGrid';
import PaginationControls from './SearchResults/PaginationControls';

import RegionList from './RegionList';
import SearchBreadcrumbs from './SearchBreadcrumbs';
import NoResultsError from './NoResultsError';

import {
  SearchLoader,
  SearchResultsWrapper,
  SearchResultsColumn,
  SearchMapColumn,
  LocationHeading,
  HeaderWrapper,
} from './SearchStage.styles';

const SearchMap = loadable(() =>
  import(/* webpackChunkName: 'SearchMap', webpackPreload: true */ './SearchMap')
);
const NO_RESULTS_ERROR =
  'Try adjusting your search by changing your dates, removing filters, or exploring the map.';

const Loader = ({ absolute }) => (
  <SearchLoader absolute={absolute}>
    <div />
  </SearchLoader>
);

function SearchStage(props) {
  const {
    device: { isMobile: isDetectedMobile },
    searchContext: {
      variant,
      loading: resultsLoading,
      errors,
      results,
      pagination,
      destination,
      nightlyRateStats,
      nightlyRateHistogram,
    },
    regionList,
    breadcrumbs,
    useCustomBreadcrumbs,
    titleText,
    adData,
    showDistance,
    optimizely_user_id,
    pathname,
    searchQuery,
    simpleHeader = false,
    stickyHeight,
    rvSearchResultsTotalCategory,
  } = props;
  const { location: searchLocation } = destination || {};
  const { mapVisible, mapLoadingVisible, mapLoaded, handleSearchMapToggle } = useSearchMap(
    isDetectedMobile
  );

  const searchMapFullScreen = !!(mapVisible && results.length);

  useEffect(() => {
    const preloadedImage = new Image();

    preloadedImage.src = props.searchContext?.results[0]?.attributes?.thumbnail?.url;
  });

  // scroll to top when loading is toggled
  useEffect(() => {
    if (canUseDOM) {
      scrollToTop();
    }
  }, [resultsLoading]);

  const histograms = {
    nightlyRateHistogram,
  };

  const renderBreadcrumbs = () => {
    if (useCustomBreadcrumbs) {
      return <Breadcrumbs {...breadcrumbs} />;
    }
    return <SearchBreadcrumbs {...breadcrumbs} />;
  };

  return (
    <div key={variant} data-variant={variant} data-opt-id={optimizely_user_id}>
      {!simpleHeader && (
        <SearchFilters
          onSearchMapToggle={handleSearchMapToggle}
          searchMapVisible={searchMapFullScreen}
          hasResults={!!results.length}
          nightlyRateStats={nightlyRateStats}
          histograms={histograms}
          stickyHeight={stickyHeight}
        />
      )}

      <SearchResultsWrapper id="search_results">
        <Flex justifyContent="space-between">
          <ResultHoverProvider>
            <SearchResultsColumn searchMapFullScreen={searchMapFullScreen}>
              <HeaderWrapper>
                {!!breadcrumbs && renderBreadcrumbs()}
                {!!(regionList && regionList.length) && <RegionList regionList={regionList} />}
                {!!titleText && (
                  <LocationHeading data-testid="page-title">{titleText}</LocationHeading>
                )}
                <SearchResultsHeader
                  pagination={pagination}
                  rvSearchResultsTotalCategory={rvSearchResultsTotalCategory}
                />
              </HeaderWrapper>
              {resultsLoading && <Loader />}
              {!!errors?.length && (
                <Box mt="10px" mb="40px">
                  {errors.map(err => {
                    return err === NO_RESULTS_ERROR ? (
                      <NoResultsError key={err} err={err} />
                    ) : (
                      <Alert variant="info">
                        <span key={err}>{err}</span>
                      </Alert>
                    );
                  })}
                </Box>
              )}
              <SearchResultsGrid
                loading={resultsLoading}
                results={results || []}
                searchLocation={searchLocation}
                mapLoadingVisible={mapLoadingVisible}
                isMobile={isDetectedMobile}
                adData={adData}
                showDistance={showDistance}
                pagination={pagination}
              />

              <PaginationControls pagination={pagination} pathname={pathname} />
            </SearchResultsColumn>
            <SearchMapColumn
              searchMapFullScreen={searchMapFullScreen}
              paginationVisible={pagination && pagination.totalPages > 1}
            >
              {mapLoaded && (
                <>
                  <SearchMap
                    fallback={<Loader absolute />}
                    searchMapFullScreen={searchMapFullScreen}
                    handleSearchMapToggle={handleSearchMapToggle}
                    loading={resultsLoading}
                    searchQuery={searchQuery}
                  />

                  <ResponsiveRender>
                    {isMobile =>
                      isMobile &&
                      searchMapFullScreen && <PaginationControls pagination={pagination} />
                    }
                  </ResponsiveRender>
                </>
              )}
            </SearchMapColumn>
          </ResultHoverProvider>
        </Flex>
      </SearchResultsWrapper>
    </div>
  );
}
export default withDeviceDetection(React.memo(SearchStage));
