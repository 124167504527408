/**
 * Enables a user to download a CSV file from the browser by
 * creating a link, attaching it to the DOM, clicking it, then cleaning up the element and link.
 *
 * @param {String} csvData - a string in csv format
 * @param {String} filename - the name of the download
 */

export const createCsvDownload = (csvData, filename) => {
    if (!csvData) return;
  
    const blob = new Blob([csvData], { type: 'text/csv' });
    const href = URL.createObjectURL(blob);
    const a = Object.assign(document.createElement('a'), {
      href,
      style: 'display:none',
      download: filename,
    });
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(href);
    a.remove();
  };
  