/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect as formikConnect } from 'formik';
import { faBolt } from '@fortawesome/pro-light-svg-icons/faBolt';

import ToggleCheckbox from 'form-fields/styled/ToggleCheckbox';

import { RentalOptionCol, RentalOptionText, ToggleWrapper, StyledIcon } from './filterStyles';

function RentalOptionsFilter(props) {
  const {
    filters: { instantBook, delivery },
    handleFormikSaveValues,
  } = props;

  const handleIBToggle = e => {
    handleFormikSaveValues({
      instantBook: e.target.checked
    });
  }

  const handleDeliveryToggle = e => {
    handleFormikSaveValues({
      delivery: e.target.checked
    });
  }

  return (
    <RentalOptionCol data-testid="filters-rental-options-checkboxes">
      <ToggleWrapper>
        <h3>Instant Book <StyledIcon icon={faBolt} /></h3>
        <ToggleCheckbox
          name="instantBook"
          isOn={instantBook}
          onChange={handleIBToggle}
        >
          <RentalOptionText noDivider>
            Book instantly without waiting for the owner to respond
          </RentalOptionText>
        </ToggleCheckbox>
      </ToggleWrapper>
      <ToggleWrapper>
        <h3>Offers Delivery</h3>
        <ToggleCheckbox
          name="delivery"
          isOn={delivery}
          onChange={handleDeliveryToggle}
        >
          <RentalOptionText noDivider>
            Owner will deliver the RV to your destination - no driving required
          </RentalOptionText>
        </ToggleCheckbox>
      </ToggleWrapper>
    </RentalOptionCol>
  );
}

export default React.memo(formikConnect(RentalOptionsFilter));
