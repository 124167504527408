import React from 'react';
import get from 'just-safe-get';

import { Button, Text } from './styles';

function PetFilterButton(props) {
  const { setFieldValue, isFilterSelected } = props;
  const selected = isFilterSelected(['pets']);

  const handleClick = () => {
    setFieldValue('pets', !selected);
  }

  return (
    <Button
      onClick={handleClick}
      type="button"
      selected={selected}
      aria-controls='pets-filter'
      aria-haspopup="true"
      aria-expanded={!!selected}
    >
      <Text ml="8px" mr="10px" fontSize="14px">
        Allows Pets
      </Text>
    </Button>
  );
}

function arePropsEqual(prevProps, nextProps) {
  const pathToObj = 'filters.pets';
  return get(prevProps, pathToObj) === get(nextProps, pathToObj);
}

export default React.memo(PetFilterButton, arePropsEqual);
