import React, { useMemo } from 'react';
import { Formik } from 'formik';
import isEqual from 'is-equal';
import ResponsiveRender from 'components/ResponsiveRender';
import SideEffect from 'form-fields/utils/SideEffect';
import shallowDiff from 'core/helpers/shallowDiff';

import { defaultFilters } from '../../helpers/Filters';

import DesktopSearchFiltersForm from './DesktopSearchFiltersForm';
import MobileSearchFiltersForm from './MobileSearchFiltersForm';

import { FiltersFlexWrapper } from './SearchFiltersForm.styles';
import useSearchFilters from '../../hooks/useSearchFilters';

function SearchFiltersForm(props) {
  const {
    currentUser,
    filters,
    hideModal = () => {},
    isFilterSelected,
    nightlyRateStats = {},
    histograms = {},
    onChange: handleChange,
    onSubmit = () => {},
    pagination = {},
  } = props;
  const { clearAllFilters } = useSearchFilters();

  const initialValues = useMemo(
    () => ({
      ...defaultFilters,
      ...filters,
      // Always set page to 1 when changing other filters
      page: 1,
    }),
    [filters]
  );

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {context => {
        const { handleSubmit, setFieldValue } = context;

        const handleFormikSaveValues = updatedValues => {
          Object.keys(updatedValues).forEach(key => {
            setFieldValue(key, updatedValues[key], false);
          });
        };

        const handleMobileClearFilters = () => {
          clearAllFilters([]);
        };

        const inputProps = {
          currentUser,
          filters,
          handleFormikSaveValues,
          handleMobileClearFilters,
          hideModal,
          isFilterSelected,
          nightlyRateStats,
          histograms,
          pagination,
          setFieldValue,
        };

        const renderFields = () => (
          <ResponsiveRender>
            {isMobile => (
              <FiltersFlexWrapper>
                {isMobile ? (
                  <MobileSearchFiltersForm {...inputProps} />
                ) : (
                  <DesktopSearchFiltersForm {...inputProps} />
                )}
              </FiltersFlexWrapper>
            )}
          </ResponsiveRender>
        );

        const onChange = handleChange
          ? ({ values: prevValues }, { values: nextValues }) => {
              const { updated } = shallowDiff(prevValues, nextValues, { is: isEqual });
              if (updated.length) {
                handleChange(nextValues);
              }
            }
          : undefined;

        return (
          <form
            id="search-filters-form"
            className="search-filters-form"
            style={{ position: 'relative' }}
            noValidate
            onSubmit={handleSubmit}
          >
            {onChange && <SideEffect onChange={onChange} />}
            {renderFields()}
          </form>
        );
      }}
    </Formik>
  );
}

export default React.memo(SearchFiltersForm);
