import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useSwiper } from 'swiper/react';
import Icon from 'core/ui/Icon';

const Chevron = styled(Icon)`
  color: ${props => props.theme.colors.coreTeal};
`;

const ButtonContainer = styled.div`
  order: -1;
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;

  @media (max-width: 992px) {
    display: none;
  }
`;

const Button = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  border: none;
  outline: none;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(63, 63, 59, 0.1);
  margin: 0 5px;
  padding: 0;
`;

export const SwiperNavButtons = () => {
  const swiper = useSwiper();
  const [isBeginning, setIsBeginning] = useState(swiper.isBeginning);
  const [isEnd, setIsEnd] = useState(swiper.isEnd);
  
  useEffect(() => {
    const handleSlideChange = () => {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    };

    swiper.on('slideChange', handleSlideChange);

    return () => {
      swiper.off('slideChange', handleSlideChange);
    };
  }, [swiper]);

  return (
    <ButtonContainer>
      <Button
        type='button'
        className={`prev-button ${isBeginning ? 'inactive' : ''}`}
        onClick={() => swiper.slidePrev()}
      >
        <Chevron icon="angle-left" size="2x" fixedWidth />
      </Button>
      <Button
        type='button'
        className={`next-button ${isEnd ? 'inactive' : ''}`}
        onClick={() => swiper.slideNext()}
      >
        <Chevron icon="angle-right" size="2x" fixedWidth />
      </Button>
    </ButtonContainer>
  );
};
