import React from 'react';

import { Button } from 'core/ui';
import useSearchFilters from '../../../hooks/useSearchFilters';

function ClearFilters() {
  const { clearAllFilters } = useSearchFilters();

  const handleClear = () => {
    clearAllFilters(['sleeps']);
  };

  return (
    <Button
      type="button"
      variant="linkMutedGrey"
      fontSize="14px"
      mt="4px"
      style={{ whiteSpace: 'nowrap' }}
      onClick={handleClear}
      data-id="clear-all-button"
      data-event-category="Search"
      data-event-action="Clear All"
      data-event-label="Top Navigation"
    >
      Clear All
    </Button>
  );
}

export default React.memo(ClearFilters);
