import React from 'react';
import styled from 'styled-components';

import { Button } from 'core/ui';
import CheckboxField from 'form-fields/styled/CheckboxField';
import { DownshiftAutocomplete } from 'components/DownshiftAutocomplete';

export const Title = styled.h4`
  font-size: 18px;
  text-align: left;
  margin: 0;

  ${({ theme }) => theme.medium`
    font-size: 14px;
    margin: 0 0 .875rem 0
  `}
`;

export const Ul = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 0.875rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
`;

export const ButtonClassType = styled(Button)`
  border-radius: 4px !important;
  width: 18rem;
  display: grid;
  grid-template-columns: 20% auto;
  align-items: center;
  color: #000000;

  &:hover {
    svg > path {
      stroke: #fff;
    }

    svg > g {
      fill: #fff;
    }
  }
`;

export const Description = styled.div`
  text-align: left;
  margin-left: 15px;
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: 0.75rem;
  margin-bottom: 0.25rem;

  p {
    margin: 0.25rem 0;
    font-size: 0.875rem;
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;

export const HiddenCheckbox = styled(CheckboxField)`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  opacity: 0.00001;

  &:checked ~ ${ButtonClassType} {
    background-color: ${props => props.theme.colors.primaryBlue};
    color: #fff;
  }

  &:checked ~ ${ButtonClassType} svg > path {
    stroke: #fff;
  }

  &:checked ~ ${ButtonClassType} svg > g {
    fill: #fff;
  }
`;

export const Label = styled.label`
  cursor: pointer;
  position: relative;

  &:hover input:not(:checked) ~ ${ButtonClassType} {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.darkBlue};
    border-color: ${props => props.theme.colors.lightGrey};
  }

  &:hover input:not(:checked) ~ ${ButtonClassType} svg > path {
    stroke: ${props => props.theme.colors.darkBlue};
  }

  &:hover input:not(:checked) ~ ${ButtonClassType} svg > g {
    fill: ${props => props.theme.colors.darkBlue};
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const VehicleMatchHeader = styled.div`
  margin-top: 2.25rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
`;

export const AutocompleteWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, auto);
  margin-top: 1.5rem;
  grid-gap: 0.874rem;

  ${({ theme }) => theme.medium`
    grid-template-columns: 30% 60%;
    margin-top: 0;
  `}
`;

export const VehicleMatchFooter = styled.div`
  margin: 1.5rem 0;
  border-top: 1px solid #d9d9d9;

  p#vehicle_match_disclaimer {
    font-size: 0.75rem;
    color: #9d9d9d;
  }
`;

export const StyledDownshiftAutocomplete = styled(DownshiftAutocomplete)`
  button {
    margin: 10px;
  }
`;

const BaseButton = styled(Button)`
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
`;

function ApplyButtons({ closeDropdown, handleClear, disabled, showClear = true }) {
  return (
    <ButtonWrapper>
      {showClear && (
        <BaseButton type="button" variant="linkPrimary" mr="15px" onClick={handleClear}>
          Clear
        </BaseButton>
      )}
      <BaseButton type="button" variant="blue" onClick={closeDropdown} disabled={disabled}>
        Apply filters
      </BaseButton>
    </ButtonWrapper>
  );
}

export default React.memo(ApplyButtons);
