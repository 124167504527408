import React from 'react';
import get from 'just-safe-get';
import styled from 'styled-components';

import { withSearchHistograms } from 'core/state/Globals';
import SliderWithInputsBargraph from 'components/SliderWithInputsBargraph';

import { HeadlineText, Indent } from './filterStyles';

const MIN_KEY = 'minGenHours';
const MAX_KEY = 'maxGenHours';
const UNLIMITED_VAL = -1;

const Wrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

function GeneratorFilter({
  filters,
  formik,
  handleSaveValues,
  includeHeader = true,
  indent = true,
  searchHistograms
}) {
  const { minGenHours, maxGenHours } = filters;
  const { buckets } = searchHistograms.generatorHistogram;
  const hoursMin = buckets[0].key;
  const hoursMax = buckets[buckets.length -1].key;

  const min = get(formik, ['values', MIN_KEY]) || minGenHours;
  const max = get(formik, ['values', MAX_KEY]) || maxGenHours;

  const handleSave = vals => {
    handleSaveValues({
      [MIN_KEY]: vals[0],
      [MAX_KEY]: vals[1],
    });
  }

  const sliderProps = {
    fieldKeyMin: MIN_KEY,
    fieldKeyMax: MAX_KEY,
    defaultMin: hoursMin,
    defaultMax: hoursMax,
    step: 1,
    sliderLabel: "hours",
    handleSaveValues: handleSave,
    min,
    max,
    isMoney: false,
    showUnlimited: true,
    selectUnlimited: maxGenHours === UNLIMITED_VAL,
    unlimitedName: "unlimited-gen-hours",
    unlimitedValue: UNLIMITED_VAL,
    srTextMax: "adjust maximum generator hours",
    srTextMin: "adjust minimum generator hours",
  };

  return (
    <Wrapper>
      {includeHeader &&
        <HeadlineText className="filterHeadline">Generator Hours Included (per night)</HeadlineText>
      }
      {indent ?
        <Indent top>
          <SliderWithInputsBargraph {...sliderProps} />
        </Indent>
      :
        <SliderWithInputsBargraph {...sliderProps} />
      }
    </Wrapper>
  );
}

export default React.memo(withSearchHistograms(GeneratorFilter));
