import React from 'react';
import styled from 'styled-components';

import { TextInput } from 'form-fields/styled/styles';

const Fieldset = styled.section`
  background-color: ${props => props.disabled ? props.theme.colors.veryLightGrey : 'transparent'};
  border: 1px solid #979797;
  border-radius: 6px;
  color: #979797;
  padding: 4px 0 0 18px;
  position: relative;
`;

const Legend = styled.label`
  font-size: 1em;
  background-color: #fff;
  position: absolute;
  top: 0;
  line-height: 1;
  margin-top: -0.5em; /* half the font size */
  padding: 0 3px;
  z-index: 2;
`;

const InputWrapper = styled.div`
  color: black;
  display: flex;
  font-size: 23px;

  input {
    border: 0;
    font-size: 18px;
    outline: 0;
  }

  input:focus {
    outline: none !important;
  }
`;

export default function FieldsetInput(props) {
  const {
    disabled,
    id,
    legend,
    name,
    onBlur,
    onChange,
    value
  } = props;

  return (
    <Fieldset disabled={disabled}>
      <Legend>{legend}</Legend>
      <InputWrapper>
        <TextInput
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="text"
          value={value}
        />
      </InputWrapper>
    </Fieldset>
  );
}
