import React from 'react';
import styled, { css } from 'styled-components';

import loadable from '@loadable/component';

import { Flex, Box } from 'core/ui';
import { useDevice } from 'core/hooks/useDevice';


const DivCarousel  = loadable(() =>
  import(/* webpackChunkName: 'RegionalNearbyGrid' */ 'components/DivCarousel')
);

const linkCSS = css`
  color: ${({ theme }) => theme.colors.bodyColor};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.linkColor};
  }
`;

const imageCSS = css`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const GridContainer = styled(Flex)`
  justify-content: flex-start;
  flex-wrap: wrap;

  .carousel-cell-div {
    width: 100%;
  }
`;

const Headline = styled.h3`
  font-size: 2rem !important;
  font-weight: bold !important;
  margin-top: 4rem !important;
  margin-bottom: 1rem !important;
  line-height: 1.2 !important;
`;

const Card = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${linkCSS}

  ${({ theme }) => theme.medium`
    margin-bottom: 2rem;
    width: calc(100%/3 - 1rem);
    margin-bottom: 0;

    :not(:last-child) {
      margin-right: 1.5rem;
    }
  `}
`;

const Photo = styled(Box)`
  width: 100%;
  height: 100%;
  height: 425px;

  ${imageCSS}
`;

const CardFooter = styled.div`
  padding: 1rem 0;

  ${({ theme }) => theme.mobile`
    padding: 0;
    text-align: center;
  `}
`;

const Footer = styled.div`
  margin: 2rem 0;
  font-size: 1.3rem;
  font-weight: bold;

  ${({ theme }) => theme.medium`
    text-align: right;
    margin: 0 0 2rem 0;
  `}
`;

const FooterLink = styled.a`
  display: inline-block;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.linkColor};
  ${linkCSS}
`;

const RegionalNearbyGrid = ({ items, headline, description, itemBaseHref = '', linkCopy, linkHref }) => {
  const { isMobile } = useDevice();

  if (!items || !items.length) return null;

  const mappedItems = items.map(({ slug, image, name }) => {
    const body = (
      <Card
        key={isMobile ? null : slug}
        href={`${itemBaseHref}${slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Photo>
          <img src={image} alt={name} loading='lazy' />
        </Photo>
        <CardFooter>
          {name}
        </CardFooter>
      </Card>
    );

    return isMobile ? (
      <div key={slug}>{body}</div>
    ) : body;
  });

  return (
    <>
      <Headline>{headline}</Headline>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <GridContainer>
        {isMobile ? (
          <DivCarousel bottomMargin="0">{mappedItems}</DivCarousel>
        ) : mappedItems}
      </GridContainer>
      <Footer>
        <FooterLink href={linkHref} target="_blank" rel="noopener noreferrer">
          {linkCopy}
        </FooterLink>
      </Footer>
    </>
  );
};

export default RegionalNearbyGrid;
