import React, { useEffect } from 'react';

import usePrevious from 'core/hooks/usePrevious';
import { maybeSaveTravelDates, maybeSaveTravelDestination } from 'core/helpers/TravelPreferences';

import { SearchExplorerContext } from '../hooks/useSearchExplorer';
import useSearchApi from '../hooks/useSearchApi';

import { trackSearchView, trackFacebookSearch } from '../helpers/Results';

/**
 * Side Effects
 */

const useTravelPreferencesUpdater = context => {
  const { results, destination, travelDates } = context;

  // The full destination comes from the server and
  // should replace either a partial or missing destination
  useEffect(() => {
    // Only track if we have results
    if (results && results.length) {
      maybeSaveTravelDestination(destination);
      maybeSaveTravelDates(travelDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);
};

const searchTracking = (context, query) => {
  const { results, destination, travelDates, numberOfTravelers, pagination } = context;
  // Only track if we have results
  if (results && results.length) {
    trackSearchView(pagination.totalResults, travelDates || {}, query);
    trackFacebookSearch(results, destination, travelDates, numberOfTravelers);
  }
};

const useSearchController = (initialState, pathname, query) => {
  // Make a call to the search API every time the URL changes
  const { state, fetchResults } = useSearchApi(initialState, pathname);

  const prevQuery = usePrevious(query);

  // Watch filters and fetch new results when they change
  useEffect(() => {
    searchTracking(state, query);
    // Don't run on first page load.
    // `prevQuery` can be an empty string after the initial load takes place,
    // so we just want to check `undefined` which denotes the first page load
    if (typeof prevQuery === 'undefined') {
      return;
    }

    // fetch new results if the filters changed
    // track search view and facebook search
    if (query !== prevQuery) {
      fetchResults(query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return state;
};

/**
 * Search Provider
 */

function SearchResultsProvider(props) {
  const { initialState, pathname, searchQuery, children } = props;

  const searchExplorerContext = useSearchController(initialState, pathname, searchQuery);

  // side effects
  useTravelPreferencesUpdater(searchExplorerContext);

  return (
    <SearchExplorerContext.Provider value={searchExplorerContext}>
      {children(searchExplorerContext)}
    </SearchExplorerContext.Provider>
  );
}

export default React.memo(SearchResultsProvider);
