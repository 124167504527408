import React from 'react';

import { Button, Flex } from 'core/ui';
import useApi from 'core/hooks/useApi';
import { createCsvDownload } from 'core/helpers/CsvDownload';
import useSearchFilters from '../../../hooks/useSearchFilters';

function ExportSearch() {
  const { buildSearchQuery } = useSearchFilters();

  const api = useApi();
  const handleExport = async ({variant}) => {
    try {
      const { data: csvData } = await api.get(
        `/v1/admin/rvs/search/export?${buildSearchQuery({})}&limit=200&report=true&variant=${variant}`
      );
      const today = new Date().getTime();
      createCsvDownload(
        csvData,
        `${today}_search_results.csv`
      );
    } catch (error) {
      alert('Something went wrong. Please try again.');  // eslint-disable-line no-alert
    }
  };

  return (
    <Flex>
      <Button
        type="button"
        variant="linkMutedGrey"
        fontSize="14px"
        mt="4px"
        onClick={() => handleExport({variant: 'control'})}
        data-id="export-results-button"
        data-event-category="Search"
        data-event-action="Export Results"
        data-event-label="Top Navigation"
      >
        Export Results
      </Button>
      <Button
        type="button"
        variant="linkMutedGrey"
        fontSize="14px"
        mt="4px"
        onClick={() => handleExport({variant: 'variant'})}
        data-id="export-variant-results-button"
        data-event-category="Search"
        data-event-action="Export Variant Results"
        data-event-label="Top Navigation"
      >
        Export Variant Results
      </Button>
    </Flex>
  );
}

export default React.memo(ExportSearch);
