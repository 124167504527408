import React from 'react';
import get from 'just-safe-get';
import styled from 'styled-components';

import { withTravelPreferences, withSearchHistograms } from 'core/state/Globals';
import SliderWithInputsBargraph from 'components/SliderWithInputsBargraph';
import { getNumberOfTravelDays } from 'core/helpers/TravelPreferences';
import { HeadlineText, Indent } from './filterStyles';

const MIN_KEY = 'minMiles';
const MAX_KEY = 'maxMiles';
const UNLIMITED_VAL = -1;

const Wrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

function MileageFilter({
  filters,
  formik,
  handleSaveValues,
  includeHeader = true,
  indent = true,
  travelPreferences,
  searchHistograms
}) {
  const { travelDates } = travelPreferences;
  const numberOfDays = getNumberOfTravelDays(travelDates) || 1;

  const { minMiles, maxMiles } = filters;
  const { buckets } = searchHistograms.nightlyMileageHistogram;
  const bucketsMin = buckets.length ? buckets[0].key * numberOfDays : 0;
  const bucketsMax = buckets.length ? buckets[buckets.length -1].key * numberOfDays : 500;

  const min = (get(formik, ['values', MIN_KEY]) || minMiles) * numberOfDays;
  const max = (get(formik, ['values', MAX_KEY]) || maxMiles) * numberOfDays;

  const calculateValsForDays = (vals) => {
    if (vals[1].toString() === UNLIMITED_VAL.toString()) {
      return {
        [MIN_KEY]: 0,
        [MAX_KEY]: -1,
      };
    }
    return {
      [MIN_KEY]: (Math.floor(vals[0] / numberOfDays)),
      [MAX_KEY]: (Math.ceil(vals[1] / numberOfDays)),
    };
  };

  const handleSave = vals => {
    handleSaveValues(calculateValsForDays(vals));
  }

  const dynamicTitle = () => {
    if (numberOfDays > 1) {
      return `Mileage Included (for a ${numberOfDays} night trip)`
    }
    return 'Mileage Included (per night)'
  } ;

  const sliderProps = {
    fieldKeyMin: MIN_KEY,
    fieldKeyMax: MAX_KEY,
    defaultMin: bucketsMin,
    defaultMax: bucketsMax,
    step: 1,
    sliderLabel: "miles",
    handleSaveValues: handleSave,
    min,
    max,
    isMoney: false,
    showUnlimited: true,
    selectUnlimited: maxMiles === UNLIMITED_VAL,
    unlimitedName: "unlimited-miles",
    unlimitedValue: UNLIMITED_VAL,
    srTextMax: "adjust maximum mileage",
    srTextMin: "adjust minimum mileage",
  };

  return (
    <Wrapper>
      {includeHeader &&
        <HeadlineText>{dynamicTitle()}</HeadlineText>
      }
      {indent ?
        <Indent top>
          <SliderWithInputsBargraph {...sliderProps} />
        </Indent>
      :
        <SliderWithInputsBargraph {...sliderProps} />
      }
    </Wrapper>
  );
}

export default React.memo(withTravelPreferences(withSearchHistograms(MileageFilter)));
