import styled from 'styled-components';

import { Button as BaseButton, Text as BaseText } from 'core/ui';

export const Button = styled(BaseButton)`
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid ${props => props.theme.borders.lightGrey};
  border-radius: 4px;
  color: ${props => props.theme.colors.primaryBlue};
  text-align: left;

  ${({ theme }) => theme.medium`
    padding-bottom: 9px !important;
  `}

  ${props => props.selected && `
    background-color: ${props.theme.colors.primaryBlue};
    color: #fff;

    &:hover {
      background-color: ${props.theme.colors.primaryBlue};
      color: #fff;
    }
  `}
`;

export const Text = styled(BaseText)`
  display: inline;
`;
