import styled from 'styled-components';

export const Header = styled.div`
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 18px;
  ${({ theme }) => theme.medium`
    font-size: 22px;
  `}
`;

export const Subheader = styled.div`
  font-weight: normal;
  font-size: 16px;
  font-size: 14px;
  ${({ theme }) => theme.medium`
    font-size: 16px;
  `}
`;

export const AutocompleteForm = styled.div`
  display: flex;
  align-items: start;

  .react-autosuggest__input {
    width: 215px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 10px 5px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    top: 51px;
    width: 100%;
    background-color: #fff;
    font-size: 16px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    font-weight: 300;
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #f4f4f4;
  }

  .search-icon {
    position: absolute;
    margin-top: 9px;
    margin-left: 11px;
  }

  .keyword-submit {
    width: 88px;
    height: calc(1.5em + 0.75rem + 2px);
    color: #fff;
    background-color: ${props => props.theme.colors.primaryBlue};
    border-color: ${props => props.theme.colors.primaryBlue};
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
`;
