import React from 'react';
import get from 'just-safe-get';
import styled from 'styled-components';

import { withSearchHistograms } from 'core/state/Globals';
import SliderWithInputsBargraph from 'components/SliderWithInputsBargraph';

import { HeadlineText, Indent } from '../filterStyles';

const MIN_KEY = 'min';
const MAX_KEY = 'max';

const Wrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

function StandAlonePriceFilter({
  filters,
  formik,
  handleSaveValues,
  includeHeader = true,
  indent = true,
  searchHistograms
}) {
  const { min: minPrice, max: maxPrice } = filters;
  const { buckets } = searchHistograms.nightlyRateHistogram;
  const priceMin = buckets[0].key;
  const priceMax = buckets[buckets.length -1].key;

  const min = get(formik, ['values', MIN_KEY]) || minPrice;
  const max = get(formik, ['values', MAX_KEY]) || maxPrice;

  const data = buckets.map((bucket => {
    return {
      distance: bucket.doc_count,
      colors: ["#91918e", "#91918e"],
      label: bucket.key,
      start: bucket.key,
      end: bucket.key
    }
  }));

  const handleSave = vals => {
    handleSaveValues({
      [MIN_KEY]: vals[0],
      [MAX_KEY]: vals[1],
    });
  }

  const sliderProps = {
    fieldKeyMin: MIN_KEY,
    fieldKeyMax: MAX_KEY,
    defaultMin: priceMin,
    defaultMax: priceMax,
    step: 1,
    sliderLabel: "price",
    handleSaveValues: handleSave,
    min,
    max,
    barData: data,
    showUnlimited: false,
    srTextMax: "adjust maximum price",
    srTextMin: "adjust minimum price",
  };

  return (
    <Wrapper>
      {includeHeader &&
        <HeadlineText>Nightly Price</HeadlineText>
      }
      {indent ?
        <Indent top>
          <SliderWithInputsBargraph {...sliderProps} />
        </Indent>
      :
        <SliderWithInputsBargraph {...sliderProps} />
      }
    </Wrapper>
  );
}

export default React.memo(withSearchHistograms(StandAlonePriceFilter));
