/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

const SwitchCheckbox = styled.input`
  height: 0;
  visibility: hidden;
  width: 0;
`;

const SwitchLabel = styled.label`
  align-items: center;
  background-color: ${props => props.isOn ? props.theme.colors.primaryBlue : 'rgba(151, 151, 151, 0.5)'};
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: space-between;
  position: relative;
  width: 48px;
`;

const SwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;

  label span {
    background: #fff;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    border-radius: 45px;
    box-shadow: 1px 2px ${props => props.theme.colors.lightGrey};
    content: '';
    height: 28px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 28px;
  }

  input:checked + label span {
    left: calc(100%);
    transform: translateX(-100%);
  }
`;

export default function ToggleChecbox({ isOn, name, onChange, children }) {
  const ToggleField = ({ field }) => {
    return (
      <SwitchCheckbox
        {...field}
        type="checkbox"
        onChange={onChange}
        checked={isOn}
        id={`${name}-switch`}
      />
    );
  };

  return (
    <SwitchWrapper>
      {children}
      <Field
        name={name}
        type="checkbox"
        component={ToggleField}
      />
      <SwitchLabel
        isOn={isOn}
        htmlFor={`${name}-switch`}
      >
        <span className="switch-button" />
      </SwitchLabel>
    </SwitchWrapper>
  );
}
