import React, { useCallback, useEffect, useMemo } from 'react';
import canUseDOM from 'can-use-dom';

import { getBrowserHistory } from 'core/utils/client';
import IsomorphicRouter from 'components/IsomorphicRouter';
import sticky from 'core/helpers/PositionSticky';
import useWindowSize from 'core/hooks/useWindowSize';
import { withTravelPreferences, withSearchHistograms } from 'core/state/Globals';

import GlobalHeader from 'components/PageHeader/GlobalHeader';
import { getFiltersFromQueryString } from '../helpers/Filters';
import { FocusProvider } from '../helpers/FocusContext';
import SearchFiltersProvider from './SearchFiltersProvider';
import SearchResultsProvider from './SearchResultsProvider';
import SearchHeader from '../components/SearchHeader';
import SearchStage from '../components/SearchStage';

// Some default values for zero result searches so the histograms don't break
const DEFAULT_FRESH_WATER_BUCKETS = {
  buckets: [
    { key: 0, doc_count: 0 },
    { key: 100, doc_count: 0 },
  ],
};
const DEFAULT_GEN_HOURS_BUCKETS = {
  buckets: [
    { key: 0, doc_count: 0 },
    { key: 24, doc_count: 0 },
  ],
};
const DEFAULT_LENGTH_BUCKETS = {
  buckets: [
    { key: 0, doc_count: 0 },
    { key: 40, doc_count: 0 },
  ],
};
const DEFAULT_MILEAGE_BUCKETS = {
  buckets: [
    { key: 0, doc_count: 0 },
    { key: 100, doc_count: 0 },
  ],
};
const DEFAULT_RATE_BUCKETS = {
  buckets: [
    { key: 0, doc_count: 0 },
    { key: 300, doc_count: 0 },
  ],
};

const Header = ({ simpleHeader, context, travelPreferences, onSubmit, ...restProps }) => {
  const { destination } = context;
  const { numberOfTravelers, travelDates } = travelPreferences;
  if (simpleHeader) {
    return <GlobalHeader />;
  }

  return (
    <SearchHeader
      {...restProps}
      enableSearchOnChange
      alwaysSearchOnChange
      searchBar={{
        destination,
        travelDates,
        numberOfTravelers,
        onSubmit,
      }}
    />
  );
};

function CombinedSearchExplorer(props) {
  const {
    searchResults: initialState,
    regionList,
    breadcrumbs,
    useCustomBreadcrumbs,
    titleText,
    adData,
    travelPreferences,
    setTravelPreferences,
    setSearchHistograms,
    isRegionalLander = false,
    simpleHeader = false,
    ...restProps
  } = props;

  // Use static history for the server and browser history for the client
  const history = canUseDOM ? getBrowserHistory() : props.history;

  const queryString = history.location.search;

  const onSubmit = useCallback(
    values => {
      // Change the search filters when search bar submits
      setTravelPreferences({
        destination: values.destination,
        travelDates: values.travelDates,
        numberOfTravelers: values.numberOfTravelers,
        towingVehicle: values?.towingVehicle,
      });
    },
    [setTravelPreferences]
  );

  // Supplement filters with travel preferences if available
  // but don't override the source of truth in the query string
  const initialFilters = useMemo(() => {
    const filters = getFiltersFromQueryString(queryString);

    [
      'isTopicalSearchPage',
      'fallbackUrl',
      'isRegionalLander',
      'rvClass',
      'destination',
      'travelDates',
      'sleeps',
      'pets',
      'delivery',
      'min',
      'max',
    ].forEach(key => {
      const value = initialState[key];

      // if the value for any entry in initialState is an object
      // iterate over it's entries and copy them over to the filters object.
      // sometimes when a page is loaded, and and the filters from the query string
      // and the initialState values differ (specifically the destination hash
      // missing a place_id), we want to copy all the entries from the value object
      // into the filters object. This prevents an extra re-render, and duplicate
      // tracking from occuring.
      if (value && typeof value === 'object') {
        // ensure the top level hash is defined before we copy over the nested entries
        if (!filters[key]) {
          filters[key] = {};
        }

        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          const filterHasNestedValue = filters[key][nestedKey];

          if (nestedValue && !filterHasNestedValue) {
            // example: { destination: { place_id: 'foo', location: 'bar', lat: 'baz', lng: 'biz' } }
            filters[key][nestedKey] = nestedValue;
          }
        });
      } else if (value && !filters[key]) {
        filters[key] = value;
      }
    });

    // if we have a stored towing vehicle
    if (initialState.towingVehicle) {
      // but no towing vehicle in our filters
      if (!filters.towingVehicle) {
        // use our stored vehicle as the filter
        filters.towingVehicle = initialState.towingVehicle;
      }

      // only supplement our towing vehicle filter with stored data if the towing weights match
      // otherwise dont override the source of truth in the query string
      if (initialState.towingVehicle?.towingWeight === filters.towingVehicle.towingWeight) {
        filters.towingVehicle = initialState.towingVehicle;
      }
    }

    return filters;
    // eslint-disable-next-line
  }, []);

  const { width } = useWindowSize(500);
  const stickyRef = React.useRef(null);
  const stickyHeight = stickyRef?.current?.els ? stickyRef.current.els[0].offsetHeight : null;

  useEffect(() => {
    setSearchHistograms({
      freshWaterTankHistogram: initialState.freshWaterTankHistogram
        ? initialState.freshWaterTankHistogram
        : DEFAULT_FRESH_WATER_BUCKETS,
      generatorHistogram: initialState.generatorHistogram
        ? initialState.generatorHistogram
        : DEFAULT_GEN_HOURS_BUCKETS,
      lengthHistogram: initialState.lengthHistogram
        ? initialState.lengthHistogram
        : DEFAULT_LENGTH_BUCKETS,
      nightlyMileageHistogram: initialState.nightlyMileageHistogram
        ? initialState.nightlyMileageHistogram
        : DEFAULT_MILEAGE_BUCKETS,
      nightlyRateStats: initialState.nightlyRateStats,
      nightlyRateHistogram: initialState.nightlyRateHistogram
        ? initialState.nightlyRateHistogram
        : DEFAULT_RATE_BUCKETS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  useEffect(() => {
    const isMobile = width < 768;
    if (!stickyRef.current && !isMobile) {
      stickyRef.current = sticky('#sticky-header');
    }

    if (stickyRef.current && isMobile) {
      // remove stickybits when on mobile
      stickyRef.current.cleanup();
      stickyRef.current = null;
    }
  }, [width]);

  return (
    <IsomorphicRouter history={history}>
      <SearchFiltersProvider
        history={history}
        initialFilters={initialFilters}
        isRegionalLander={isRegionalLander}
      >
        {({ pathname, searchQuery }) => (
          <SearchResultsProvider
            initialState={initialState}
            pathname={pathname}
            searchQuery={searchQuery}
          >
            {context => (
              <>
                <FocusProvider>
                  <div id="sticky-header" style={{ zIndex: '4' }}>
                    <Header
                      context={context}
                      simpleHeader={simpleHeader}
                      onSubmit={onSubmit}
                      travelPreferences={travelPreferences}
                      {...restProps}
                    />
                  </div>
                  <SearchStage
                    searchContext={context}
                    regionList={regionList}
                    breadcrumbs={breadcrumbs}
                    titleText={titleText}
                    useCustomBreadcrumbs={useCustomBreadcrumbs}
                    adData={adData}
                    showDistance
                    optimizely_user_id={initialState.optimizely_user_id}
                    pathname={pathname}
                    searchQuery={searchQuery}
                    simpleHeader={simpleHeader}
                    stickyHeight={stickyHeight}
                    rvSearchResultsTotalCategory={initialState.rvSearchResultsTotalCategory}
                  />
                </FocusProvider>
              </>
            )}
          </SearchResultsProvider>
        )}
      </SearchFiltersProvider>
    </IsomorphicRouter>
  );
}

export default withSearchHistograms(
  withTravelPreferences(CombinedSearchExplorer, { includeSetter: true }),
  {
    includeSetter: true,
  }
);
