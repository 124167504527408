import styled from 'styled-components';

import { Button } from 'core/ui';
import CheckboxField from 'form-fields/styled/CheckboxField';
import StyledCheckbox from 'form-fields/styled/StyledCheckbox';

export const Title = styled.h4`
  font-size: 18px;
  text-align: left;
  margin: 0;

  ${({ theme }) => theme.medium`
    font-size: 14px;
    text-transform: uppercase;
  `}
`;

export const ButtonClassType = styled(Button)`
  border-radius: 4px !important;
  flex: 0 0 120px;

  &:hover {
    svg > path {
      stroke: #fff;
    }

    svg > g {
      fill: #fff;
    }
  }
`;

export const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  margin: 0;

  ${({ theme }) => theme.medium`
    margin-top: 15px;

    &:first-of-type {
      margin-top: 0;
    }
  `}
`;

export const Description = styled.div`
  text-align: left;
  margin-left: 15px;
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: 14px;
`;

export const ButtonWrapper = styled.div`
  text-align: right;
  margin-top: 20px;
`;

export const HiddenCheckbox = styled(CheckboxField)`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  opacity: 0.00001;

  &:checked ~ ${ButtonClassType} {
    background-color: ${props => props.theme.colors.primaryBlue};
    color: #fff;
  }

  &:checked ~ ${ButtonClassType} svg > path {
    stroke: #fff;
  }

  &:checked ~ ${ButtonClassType} svg > g {
    fill: #fff;
  }
`;

export const Label = styled.label`
  cursor: pointer;
  position: relative;

  &:hover input:not(:checked) ~ ${ButtonClassType} {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.darkBlue};
    border-color: ${props => props.theme.colors.lightGrey};
  }

  &:hover input:not(:checked) ~ ${ButtonClassType} svg > path {
    stroke: ${props => props.theme.colors.darkBlue};
  }

  &:hover input:not(:checked) ~ ${ButtonClassType} svg > g {
    fill: ${props => props.theme.colors.darkBlue};
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const MobileCheckbox = styled(StyledCheckbox)`
  display: flex;

  label {
    font-weight: bold;

    ${({ theme }) => theme.medium`
      font-weight: normal;
    `}
  }
`;

export const SelectAllButton = styled(Button)`
  font-weight: 400;

  ${({ theme }) => theme.medium`
    font-weight: 600;
  `}
`;
