import React from 'react';
import styled from 'styled-components';

import { Flex, Text } from 'core/ui';
import SearchSortForm from '../SearchFilters/SearchSortForm';

const SearchResultsHeaderStyled = styled(Flex)`
  padding-top: 0;

  ${({ theme }) => theme.medium`
    padding-top: 7px;
  `}
`;

const SearchSortFormWrapper = styled.div`
  margin-right: -16px;

  ${({ theme }) => theme.medium`
    margin-right: 0;
  `}
`;

function SearchResultsHeader(props) {
  const { pagination, rvSearchResultsTotalCategory } = props;

  return (
    <SearchResultsHeaderStyled
      id="search_results_header"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text fontWeight="bold" fontSize="14px">
        {!pagination || pagination.totalResults === 0 ? '0' : pagination.resultsCountDisplay} {rvSearchResultsTotalCategory && rvSearchResultsTotalCategory} RV Rentals
      </Text>
      <SearchSortFormWrapper>
        <SearchSortForm />
      </SearchSortFormWrapper>
    </SearchResultsHeaderStyled>
  );
}

export default React.memo(SearchResultsHeader);
