import { useState, useCallback, useEffect, useRef } from 'react';
import canUseDom from 'can-use-dom';

function getSize() {
  return {
    height: canUseDom ? window.innerHeight : 0,
    width: canUseDom ? window.innerWidth : 0,
  };
}

export default function useWindowSize(debounceMs) {
  const timeoutHandlerRef = useRef(null);

  const [windowSize, setWindowSize] = useState(getSize());

  const handleResize = useCallback(() => {
    if (timeoutHandlerRef.current) {
      clearTimeout(timeoutHandlerRef.current);
    }

    timeoutHandlerRef.current = setTimeout(() => {
      setWindowSize(getSize());
    }, debounceMs);
  }, [debounceMs]);

  useEffect(() => {
    if (!canUseDom) {
      return;
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return windowSize;
}
