import React from 'react';
import { Button } from 'core/ui';
import styled from 'styled-components';

import CampfireVan from 'images/search/campfire-van.svg';
import useSearchFilters from '../hooks/useSearchFilters';
import { defaultFilters } from '../helpers/Filters';

const ErrorHeader = styled.h2`
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const ErrorMessage = styled.div`
  text-align: center;
  white-space: pre-line;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

export default function NoResultsError({ err }) {
  const { clearAllFilters, isFilterSelected } = useSearchFilters();

  const applicableKeys = Object.keys(defaultFilters).filter(k => k !== 'sleeps');
  const hasFiltersSelected = isFilterSelected(applicableKeys);

  const handleClear = () => {
    clearAllFilters(['sleeps']);
  };

  return (
    <div style={{ margin: '32px 0' }}>
      <img
        src={CampfireVan}
        alt="An RV next to a campfire."
        style={{ display: 'block', margin: '0 auto' }}
      />
      <ErrorHeader>No RV Listings Found</ErrorHeader>
      <ErrorMessage>{err}</ErrorMessage>
      {hasFiltersSelected && (
        <ButtonContainer>
          <Button type="button" variant="greyBorder" onClick={handleClear}>
            Remove All Filters
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
}
