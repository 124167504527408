import React from 'react';

import RegionalSearchResult from './RegionalSearchResult';

function RegionalSearchGrid({ region, results, searchLink }) {
  return (
    <div className="container">
      {Object.entries(results).map((e) => (
        <RegionalSearchResult
          key={e[0]}
          title={e[0]}
          results={e[1]}
          region={region}
          searchLink={searchLink}
        />
      ))}
    </div>
  )
}

export default RegionalSearchGrid;
