/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect as formikConnect } from 'formik';
import StyledCheckbox from 'form-fields/styled/StyledCheckbox';

import { CheckboxWrapper, Label, Row } from './filterStyles';

function ElectricServiceFilter(props) {
  const {
    handleFormikSaveValues,
    formik
  } = props;

  const handleSaveValues = e => {
    const amps = e.currentTarget.name.slice(-2);
    let payload = {}
    if (amps === '30') {
      payload = {
        30: e.currentTarget.checked,
        50: formik.values.electricService['50']
      }
    }
    if (amps === '50') {
      payload = {
        30: formik.values.electricService['30'],
        50: e.currentTarget.checked
      }
    }

    handleFormikSaveValues({
      electricService: { ...payload }
    })};

  return (
    <Row data-testid="filters-electric-service-checkboxes">
      <Label>Electric Service</Label>
      <CheckboxWrapper>
        <StyledCheckbox name='electricService.30' onChange={handleSaveValues}>30 amp</StyledCheckbox>
      </CheckboxWrapper>
      <CheckboxWrapper>
        <StyledCheckbox name='electricService.50' onChange={handleSaveValues}>50 amp</StyledCheckbox>
      </CheckboxWrapper>
    </Row>
  );
}

export default React.memo(formikConnect(ElectricServiceFilter));
