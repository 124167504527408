import React from 'react';
import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import RadioButton from 'form-fields/styled/RadioButton';

import DropdownFilterText from './DropdownFilterText';
import { SortFilterOptions as options } from '../../../helpers/SortFilters';

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  margin-bottom: 3px;
`;

function SortButtons({ closeDropdown }) {
  return (
    <Ul>
      {options.map(({ value, label }) => (
        <Li key={value}>
          <RadioButton
            name="sort"
            value={value}
            id={value}
            defaultValue="1"
            onClick={closeDropdown}
          >
            {label}
          </RadioButton>
        </Li>
      ))}
    </Ul>
  );
}

function SortFilter() {
  return (
    <Dropdown
      buttonVariant="link"
      component={props => <SortButtons {...props} />}
      dropdownStyles={{ padding: '7px 10px' }}
      uniqueId="sort-filter"
      data-event-category="Search"
      data-event-action="Sort by"
      data-event-label="Search Filters"
    >
      <DropdownFilterText>Sort by</DropdownFilterText>
    </Dropdown>
  );
}

export default React.memo(SortFilter);
