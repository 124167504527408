import React from 'react';
import { connect as connectFormik } from 'formik';

import { withTravelPreferences } from 'core/state/Globals';
import { getNumberOfTravelDays } from 'core/helpers/TravelPreferences';
import { Button, Box, Flex } from 'core/ui';
import { DrivableClassFilterOptions, TowableClassFilterOptions } from 'core/helpers/RV';
import styled from 'styled-components';

import {
  ModalHeading,
  ModalHeader,
  StickyHeader,
  CloseButtonStyled,
  StickyButton,
} from 'components/Modal';
import AmenitiesFilter from './filters/AmenitiesFilter';
import ElectricServiceFilter from './filters/ElectricServiceFilter';
import FreshWaterTankFilter from './filters/FreshWaterTankFilter';
import GeneratorFilter from './filters/GeneratorFilter';
import KeywordFilter from './filters/KeywordFilter';
import LengthFilter from './filters/LengthFilter';
import MileageFilter from './filters/MileageFilter';
import MiscFilter from './filters/MiscFilter';
import PriceFilter from './filters/PriceFilter/StandAlonePriceFilter';
import RentalOptionsFilter from './filters/RentalOptionsFilter';
import RulesFilter from './filters/RulesFilter';
import RvTypeFilter from './filters/RvTypeFilter';

import {
  Col,
  HeadlineText,
} from './filters/filterStyles'

const MobileStickyHeader = styled(StickyHeader)`
  width: 100%;
`;

const SpacedCol = styled(Col)`
  margin-top: 25px !important;
  padding-bottom: 25px !important;
  width: 100%;
`;

const ToggleCol = styled(SpacedCol)`
  input {
    margin-left: 40px;
  }

  input + label {
    width: 70px;
  }
`;

const BottomRow = styled(StickyButton)`
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: space-between;
  padding: 25px 20px 15px !important;
  position: fixed !important;
`;

const HistogramBox = styled(Box)`
  padding: 0 10px;
`;

const SmallHeadlineText = styled(HeadlineText)`
  font-size: 1.4em;
`;

function MobileSearchFiltersForm(props) {
  const {
    filters,
    formik,
    handleFormikSaveValues,
    handleMobileClearFilters,
    hideModal,
    pagination,
    travelPreferences,
  } = props;
  const { travelDates } = travelPreferences;
  const numberOfDays = getNumberOfTravelDays(travelDates) || 1;
  const dynamicMileageTitle =
    (numberOfDays > 1) ?
      `Mileage Included<br />(for a ${numberOfDays} night trip)`
    :
      'Mileage Included (per night)'
    ;

  return (
    <>
      <MobileStickyHeader>
        <ModalHeader justifyContent="space-between">
          <Flex ml="42%">
            <ModalHeading>Filters</ModalHeading>
          </Flex>

          <CloseButtonStyled type="button" onClick={hideModal} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </CloseButtonStyled>
        </ModalHeader>
      </MobileStickyHeader>

      <ToggleCol margin="15px 0 0x">
        <SmallHeadlineText>Rental Options</SmallHeadlineText>
        <RentalOptionsFilter
          handleFormikSaveValues={handleFormikSaveValues}
          filters={filters}
        />
      </ToggleCol>

      <SpacedCol>
        <SmallHeadlineText>Nightly Price</SmallHeadlineText>
        <HistogramBox>
          <PriceFilter
            filters={filters}
            formik={formik}
            handleSaveValues={handleFormikSaveValues}
            includeHeader={false}
            indent={false}
          />
        </HistogramBox>
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText>RV Type</SmallHeadlineText>
        <RvTypeFilter
          drivableOptions={DrivableClassFilterOptions}
          towableOptions={TowableClassFilterOptions}
          isMobile
        />
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText>Power and Water</SmallHeadlineText>
        <ElectricServiceFilter
          filters={filters}
          formik={formik}
          handleFormikSaveValues={handleFormikSaveValues}
        />
        <MiscFilter
          amenity="Electric Generator"
          modifier="an"
          filters={filters}
          handleFormikSaveValues={handleFormikSaveValues}
        />
        <MiscFilter
          amenity="Hot & Cold Water Supply"
          filters={filters}
          handleFormikSaveValues={handleFormikSaveValues}
        />
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText dangerouslySetInnerHTML={{ __html: dynamicMileageTitle}} />
        <HistogramBox>
          <MileageFilter
            handleSaveValues={handleFormikSaveValues}
            formik={formik}
            filters={filters}
            includeHeader={false}
            indent={false}
          />
        </HistogramBox>
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText>Generator Hours Included<br />(per night)</SmallHeadlineText>
        <HistogramBox>
          <GeneratorFilter
            filters={filters}
            formik={formik}
            handleSaveValues={handleFormikSaveValues}
            includeHeader={false}
            indent={false}
          />
        </HistogramBox>
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText>Rental Rules</SmallHeadlineText>
        <RulesFilter
          handleFormikSaveValues={handleFormikSaveValues}
        />
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText>Amenities</SmallHeadlineText>
        <AmenitiesFilter
          handleFormikSaveValues={handleFormikSaveValues}
        />
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText>Length (in feet)</SmallHeadlineText>
        <HistogramBox>
          <LengthFilter
            filters={filters}
            formik={formik}
            handleSaveValues={handleFormikSaveValues}
            includeHeader={false}
            indent={false}
          />
        </HistogramBox>
      </SpacedCol>

      <SpacedCol>
        <SmallHeadlineText>Fresh Water Tank (in gallons)</SmallHeadlineText>
        <HistogramBox>
          <FreshWaterTankFilter
            filters={filters}
            handleSaveValues={handleFormikSaveValues}
            includeHeader={false}
            indent={false}
          />
        </HistogramBox>
      </SpacedCol>

      <SpacedCol noDivider>
        <SmallHeadlineText>Keyword Search</SmallHeadlineText>
        <KeywordFilter filters={filters} />
      </SpacedCol>

      <BottomRow>
        <Button
          type="button"
          variant="linkPrimaryLarge"
          fontSize="14px"
          onClick={() => handleMobileClearFilters()}
          style={{textAlign: 'left'}}
        >
          Clear all
        </Button>

        <Button type="submit" variant="blueLarge" py="15px">
          Show {pagination.totalResults} RVs
        </Button>
      </BottomRow>
    </>
  )
};

export default connectFormik(React.memo(withTravelPreferences(MobileSearchFiltersForm)));
