import 'core/styled';
import '@glidejs/glide/dist/css/glide.core.css';
import { renderContainer, registerModals } from 'core/utils/client';
import DatePickerModal from 'components/DatePicker/DatePickerModal';
import SearchHeader from 'details/containers/SearchHeader';
import CombinedSearchExplorer from 'search/containers/CombinedSearchExplorer';
import RegionalSearchGrid from 'search/containers/RegionalSearchGrid';
import RegionalCopy from 'search/containers/RegionalCopy';
import RegionalNearby from 'search/containers/RegionalNearby';
import RegionalSearchExperiment from 'search/containers/RegionalSearchExperiment';
import EventsCarousel from 'content/events/Carousel';

registerModals({
  DatePicker: DatePickerModal,
});

renderContainer('SearchHeader', SearchHeader);

renderContainer('CombinedSearchExplorer', CombinedSearchExplorer);

renderContainer('RegionalSearchGrid', RegionalSearchGrid);

renderContainer('RegionalCopy', RegionalCopy);

renderContainer('RegionalNearby', RegionalNearby);

renderContainer('RegionalSearchExperiment', RegionalSearchExperiment);

renderContainer('EventsCarousel', EventsCarousel);