import styled from 'styled-components';

import { Flex, Box } from 'core/ui';

export const FilterBox = styled(Box)`
  margin-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.veryLightGrey};
  padding-bottom: 10px;
  width: 100%;

  &:last-of-type {
    border-bottom: none;
  }

  ${({ theme }) => theme.medium`
    > button {
      padding: 8px 10px;
    }

    border-bottom: none;
    margin: 0;
    margin-right: 10px;
    padding: 0;
    width: auto;
  `}
`;

export const ClassFilterBox = styled(FilterBox)`
  width: 50%;

  ${({ theme }) => theme.medium`
    width: auto;
  `}
`;

export const FiltersFlexWrapper = styled(Flex)`
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;

  ${({ theme }) => theme.medium`
    flex-wrap: nowrap;
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const FilterBoxInstantBook = styled(FilterBox)`
  order: -1;
  margin-top: 15px;

  ${({ theme }) => theme.medium`
    order: 0;
    margin-top: 0;
  `}
`;

export const FilterBoxAdditionalFilters = styled(FilterBox)`
  order: 15;

  ${({ theme }) => theme.medium`
    order: 0;
  `}
`;

export const StyledFilterBox = styled.div`
  order: -1;
  margin-top: 15px;

  ${({ theme }) => theme.medium`
    order: 0;
    margin-top: 0;
    margin-right: 10px;
  `}
`;
