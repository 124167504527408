import React from 'react';
import { connect as formikConnect } from 'formik';

import ExplanationPopover from 'components/ExplanationPopover2';
import { CheckboxWrapper, Grid, GridColumn, Row, StyledCheckboxNoLabel } from './filterStyles';

function RulesFilter({ handleFormikSaveValues }) {
  const handleSaveValues = e =>
    handleFormikSaveValues({
      [e.currentTarget.name]: e.currentTarget.checked,
    });

  const rule = (name, label, hasTooltip = false) => {
    return (
      <Row>
        <CheckboxWrapper>
          <StyledCheckboxNoLabel name={name} onChange={handleSaveValues}>
            {label}
          </StyledCheckboxNoLabel>
          {hasTooltip &&
            <ExplanationPopover
              content="Ability to rent an RV from an owner's home or property"
              buttonStyle={{marginLeft: '-9px'}}
              trigger="mouseenter focus click"
            />
          }
        </CheckboxWrapper>
      </Row>
  )};

  return (
    <Grid data-testid="filters-rental-rules-checkboxes">
      <GridColumn>
        {rule('cancellationPolicy.Flexible', 'Flexible cancellation')}
        {rule('cancellationPolicy.Standard', 'Standard cancellation')}
      </GridColumn>
      <GridColumn>
        {rule('smoking', 'Allows smoking')}
        {rule('isStationary', 'Offers stationary rentals', true)}
      </GridColumn>
      <GridColumn>
        {rule('pets', 'Allows pets')}
      </GridColumn>
    </Grid>
  );
}

export default React.memo(formikConnect(RulesFilter));
