import { differenceInCalendarDays, parseISO } from 'date-fns';

import * as Query from 'core/helpers/Query';
import SafeTracking from 'tracking/SafeTracking';

import paginate from 'core/helpers/paginate';

// Add some default context to pagination
const withDefaultPagination = pagination =>
  paginate({
    perPage: 27,
    maxVisible: 7,
    totalResults: 0,
    currentPage: 0,
    totalPages: 0,
    ...pagination,
  });

const parseErrorsToArray = errorMap => {
  if (!errorMap) {
    return null;
  }

  return Object.keys(errorMap).reduce((acc, key) => {
    const errs = errorMap[key];

    if (!errs) {
      return acc;
    }

    return [...acc, ...errs];
  }, []);
};

export const parseServerResponse = payload => {
  const {
    results,
    pagination,
    variant,
    destination,
    travelDates,
    numberOfTravelers,
    error,
    nightlyRateStats,
    nightlyRateHistogram,
    scores,
  } = payload;
  return {
    variant,
    errors: parseErrorsToArray(error),
    destination,
    travelDates,
    numberOfTravelers,
    results: results || [],
    pagination: withDefaultPagination(
      { ...pagination, perPage: pagination.perPage || results?.length } || {}
    ),
    nightlyRateStats: nightlyRateStats || {},
    nightlyRateHistogram: nightlyRateHistogram || {},
    scores,
  };
};

export const trackSearchView = (numberOfResults, travelDates = {}, query) => {
  const { startDate, endDate } = travelDates;
  // check if datedSearch is an empty object
  const datedSearch = Object.keys(travelDates).length > 0;

  // make change here.
  const trackingPayload = {
    rentalLength:
      endDate && startDate ? differenceInCalendarDays(parseISO(endDate), parseISO(startDate)) : '',
    daysFromRental: startDate ? differenceInCalendarDays(parseISO(startDate), new Date()) : '',
    numberOfResults,
    datedSearch,
    startDate: datedSearch && startDate,
    endDate: datedSearch && endDate,
    query,
  };

  if (Query.parse(query)?.location === 'Near By') {
    trackingPayload.locationType = 'Near Me';
  }

  SafeTracking.track('Search Executed', trackingPayload, {
    rvsIntegrations: { all: false, segment: true },
  });
};

const canTrackRegionSearch = (resultIds, destination) => {
  if (!resultIds || !resultIds.length || !destination) {
    return false;
  }

  return !!(destination.city && destination.state);
};

const getSearchEventProperties = (resultIds, destination, travelDates, numberOfTravelers) => {
  const baseEvent = {
    content_type: 'hotel',
    content_ids: resultIds,
    country: 'United States',
    city: destination.city,
    region: destination.state,
    num_adults: numberOfTravelers || 1,
  };

  // Only add these properties if we have them or facebook will complain
  if (travelDates && travelDates.startDate && travelDates.endDate) {
    baseEvent.checkin_date = travelDates.startDate;
    baseEvent.checkout_date = travelDates.endDate;
  }

  return baseEvent;
};

export const trackFacebookSearch = (results, destination, travelDates, numberOfTravelers) => {
  const resultIds = results.slice(0, 6).map(result => result.id);

  if (canTrackRegionSearch(resultIds, destination)) {
    const properties = getSearchEventProperties(
      resultIds,
      destination,
      travelDates,
      numberOfTravelers
    );

    SafeTracking.trackFacebook('Search', properties, {
      integrations: { all: false, segment: true },
    });
  }
};
