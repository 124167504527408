import React from 'react';

import { Box, Flex } from 'core/ui';
import { DrivableClassFilterOptions, TowableClassFilterOptions } from 'core/helpers/RV';
import { getStateFromDOM } from 'core/utils/client';

import ClassDropdown from './filters/ClassTypes/ClassDropdown';
import AllFiltersDropdown from './filters/AllFilters';
import ClearFilters from './filters/ClearFilters';
import ExportSearch from './filters/ExportSearch';
import PriceFilter from './filters/PriceFilter';
import TripDropdown from './filters/TripPlanning';
import PetFilterButton from './filters/DesktopFilterButtons/PetFilterButton';
import DeliveryButtonDesktop from './filters/DesktopFilterButtons/DeliveryButtonDesktop';

import {
  ClassFilterBox,
  FilterBoxAdditionalFilters,
  StyledFilterBox,
} from './SearchFiltersForm.styles';

function DesktopSearchFiltersForm(props) {
  const {
    currentUser,
    filters,
    isFilterSelected,
    pagination,
    histograms,
    handleFormikSaveValues,
    setFieldValue,
  } = props;

  let isReportUser = false;
  try {
    // Store list of allowed users in Doppler. NOTE: Do not change this secret value without notifying cae
    const allowedIds = getStateFromDOM('env.searchReportUsers')?.split(',');
    isReportUser = allowedIds?.includes(currentUser?.id);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to get search report users', e);
  }

  return (
    <>
      <Flex flexWrap="wrap">
        <div className="col pb1">
          <Box mr="10px" id="filter-box__price">
            <PriceFilter
              isFilterSelected={isFilterSelected}
              filters={filters}
              nightlyRateHistogram={histograms.nightlyRateHistogram || {}}
              handleFormikSaveValues={handleFormikSaveValues}
            />
          </Box>
        </div>
        <div className="col pb1">
          <ClassFilterBox id="filter-box__drivable">
            <ClassDropdown
              title="Drivable"
              options={DrivableClassFilterOptions}
              filters={filters}
            />
          </ClassFilterBox>
        </div>
        <div className="col pb1">
          <ClassFilterBox id="filter-box__towable">
            <ClassDropdown title="Towable" options={TowableClassFilterOptions} filters={filters} />
          </ClassFilterBox>
        </div>
        <div className="col pb1">
          <Box mr="10px" id="filter-box__trip">
            <TripDropdown
              title="Trip Planning"
              isFilterSelected={isFilterSelected}
              filters={filters}
              handleFormikSaveValues={handleFormikSaveValues}
              totalResults={pagination.totalResults}
            />
          </Box>
        </div>
        <div className="col pb1">
          <StyledFilterBox id="filter-box__pet_filter_desktop">
            <PetFilterButton
              isFilterSelected={isFilterSelected}
              setFieldValue={setFieldValue}
              filters={filters}
            />
          </StyledFilterBox>
        </div>
        <div className="col pb1">
          <Box mr="10px">
            <DeliveryButtonDesktop
              isFilterSelected={isFilterSelected}
              setFieldValue={setFieldValue}
              filters={filters}
            />
          </Box>
        </div>
        <div className="col pb1">
          <FilterBoxAdditionalFilters id="filter-box__additional-filters">
            <AllFiltersDropdown
              title="Filters"
              isFilterSelected={isFilterSelected}
              filters={filters}
              handleFormikSaveValues={handleFormikSaveValues}
              totalResults={pagination.totalResults}
              drivableOptions={DrivableClassFilterOptions}
              towableOptions={TowableClassFilterOptions}
            />
          </FilterBoxAdditionalFilters>
        </div>
      </Flex>
      <Flex mr="10px" pb="1rem">
        <ClearFilters />
      </Flex>
      {isReportUser && (
        <Flex mr="10px" pb="1rem">
          <ExportSearch />
        </Flex>
      )}
    </>
  );
}

export default React.memo(DesktopSearchFiltersForm);
