import React from 'react';
import styled from 'styled-components';

import { Text as BaseText } from 'core/ui';

const Text = styled(BaseText)`
  display: inline;
`;

function DropdownFilterText({ children }) {
  return (
    <Text ml="8px" mr="10px" fontSize="14px">
      {children}
    </Text>
  );
}

export default DropdownFilterText;
