import React from 'react';
import { Formik } from 'formik';

import SideEffect from 'form-fields/utils/SideEffect';
import SafeTracking from 'tracking/SafeTracking';

import useSearchFilters from '../../hooks/useSearchFilters';
import SortFilter from './filters/SortFilter';
import { SortFilterOptions } from '../../helpers/SortFilters';

const trackSort = (sortValue) => {
  const [sortOption = {}] = SortFilterOptions.filter(opt => opt.value === sortValue);

  SafeTracking.track('Search Sort Used',
    {
      newSortValue: sortOption?.label,
    },
    { rvsIntegrations: { all: false, segment: true } });
};

function SearchSortForm() {
  const { filters, changeSearchFilters } = useSearchFilters();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        sort: filters.sort || '',
      }}
      render={(context) => {
        return (
          <form onSubmit={context.handleSubmit}>
            <SideEffect
              onChange={({ values: prevValues }, { values: nextValues }) => {
                if (prevValues.sort !== nextValues.sort) {
                  trackSort(nextValues.sort);
                  changeSearchFilters({ sort: nextValues.sort });
                }
              }}
            />
            <SortFilter />
          </form>
        );
      }}
    />
  );
}

export default SearchSortForm;
