/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';

import SlimCard from 'core/ui/SlimCard';
import mediaQuery from 'core/styles/mediaQuery';

import RegionalNearbyGrid from 'search/components/RegionalNearbyGrid';
import RegionalNearbyCampgrounds from 'search/components/RegionalNearbyCampgrounds';
import WhyRentWithRVShare from 'search/components/WhyRentWithRVShare';
import DumpstationGrid from 'search/components/RegionalNearbyDumpstations';
import SimpleDumpstationGrid from 'search/components/RegionalNearbyDumpstations/simple';


const ClassSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
  column-gap: 1rem;

  ${mediaQuery.belowMedium`
    flex-wrap: wrap;
    row-gap: 1rem;
  `}
`;

const Headline = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Footer = styled.div`
  margin: 2rem 0;
  font-size: 1.3rem;
  font-weight: bold;

  ${({ theme }) => theme.medium`
    text-align: right;
    margin: 0 0 2rem 0;
  `};
`;

const FooterLink = styled.a`
  display: inline-block;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.bodyColor};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.linkColor};
  }
`;

const BrowseSectionWrapper = styled.div`
  margin-bottom: 5rem;
`;

const RegionalNearby = ({
  location,
  stateParam,
  nationalParks,
  stateParks,
  nationalForests,
  nationalSites,
  campgrounds,
  roadTrips,
  dumpstation,
  dumpstations,
  rvStorage,
  displayWhyRent = true,
  displayWhyRentTop = false,
  browseByCategorySelections = [],
  useSimpleDumpstation = false,
}) => {

  function getDataTestId(title) {
    if (title.includes('towable')) {
      return 'towable-cards';
    }

    if (title.includes('motorhome')) {
      return 'motorhome-cards';
    }

    if (title.includes('searches')) {
      return 'popular-cards';
    }
    return '';
  }

  return (
    <div>
      {displayWhyRent && displayWhyRentTop && (
        <>
          <Headline data-testid="why-rent-title">Why Rent with RVshare?</Headline>
          <WhyRentWithRVShare />
        </>
      )}
      {!!browseByCategorySelections.length && browseByCategorySelections.map((selection) => (

        <BrowseSectionWrapper key={selection.title}>
          <h1>{selection.title}</h1>
          <ClassSelectionContainer data-testId={getDataTestId(selection.title)}>
            {selection.selections.map((entry, index) => (
              <SlimCard
                title={entry.title}
                slug={entry.slug}
                caption={entry.caption}
                url={entry.url}
                location={location}
                key={`class-card${index}`}
              />
            ))}
          </ClassSelectionContainer>
        </BrowseSectionWrapper>
      ))}
      <RegionalNearbyGrid
        items={roadTrips.items}
        headline={`Road Trips Starting from ${location}`}
        description={roadTrips.description}
        linkCopy={`View all road trips starting from ${location}`}
        linkHref={`/road-trips?state=${stateParam}`}
        itemBaseHref="/road-trips/"
      />
      <RegionalNearbyGrid
        items={nationalParks.items}
        headline={`Best National Parks Near ${location}`}
        description={nationalParks.description}
        linkCopy={`View all National Parks near ${location}`}
        linkHref="/national-parks"
        itemBaseHref="/national-parks/"
      />
      <RegionalNearbyGrid
        items={stateParks.items}
        headline={`Popular State Parks Near ${location}`}
        description={stateParks.description}
        linkCopy={`View state parks near ${location}`}
        linkHref={`/state-parks/${stateParam}`}
        itemBaseHref="/state-parks/"
      />
      {nationalForests && (
        <RegionalNearbyGrid
          items={nationalForests.items}
          headline={`National Forests Near ${location}`}
          description={nationalForests.description}
          linkCopy={`View national forests near ${location}`}
          linkHref={`/national-forests/${stateParam}`}
          itemBaseHref="/national-forests/"
        />
      )}
      <RegionalNearbyGrid
        items={nationalSites.items}
        headline={`Must-see Monuments and Landmarks Near ${location}`}
        description={nationalSites.description}
        linkCopy={`View national sites and monuments near ${location}`}
        linkHref={`/national-sites/${stateParam}`}
        itemBaseHref="/national-sites/"
      />
      <RegionalNearbyCampgrounds
        items={campgrounds.items}
        headline="RVshare’s Top Picks for Nearby RV Parks & Campgrounds"
        description={campgrounds.description}
        linkCopy={`View campgrounds near ${location}`}
        linkHref={`/blog/top-10-campgrounds?state=${stateParam}`}
      />
      {useSimpleDumpstation && dumpstation && dumpstation.content && dumpstations && !!dumpstations.length && (
        <SimpleDumpstationGrid
          location={location}
          dumpstation={dumpstation}
          stateParam={stateParam}
        />
      )}
      {!useSimpleDumpstation && dumpstation && dumpstation.content && (
        <DumpstationGrid
          location={location}
          dumpstation={dumpstation}
          dumpstations={dumpstations}
          stateParam={stateParam}
        />
      )}
      {rvStorage && rvStorage.content && (
        <>
          <Headline>RV Storage Near {location}</Headline>
          <div>{rvStorage.content}</div>
          <Footer>
            <FooterLink href={`/rv-storage/${stateParam}`} target="_blank" rel="noopener noreferrer">
              View all RV storage facilities near {location}
            </FooterLink>
          </Footer>
        </>
      )}
      {displayWhyRent && !displayWhyRentTop && (
        <>
          <Headline data-testid="why-rent-title">Why Rent with RVshare?</Headline>
          <WhyRentWithRVShare />
        </>
      )}
    </div>
  )
};

export default RegionalNearby;
